import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../styles/MixMonitor.css';
import AudioSpectrum from 'react-audio-spectrum';

// Função simples para detectar dispositivos móveis
const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile|tablet/i.test(
      userAgent
    ) ||
    (typeof window !== 'undefined' && window.innerWidth <= 768)
  );
};

const API_KEY = 'AIzaSyC0rreNeqKXjqOrUe861EhbK9zKHZQdbhk'; // Chave do Google Maps

// Dados estáticos das afiliadas
const AFILIADAS = [
  {
    id: 1,
    nome: 'Alta Paulista - 103.9 FM',
    lat: -21.85,
    lng: -50.45,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_ALTAPAULISTA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_ALTAPAULISTAAAC.aac',
    frequencia: '103.9 FM',
    status: 'ao vivo',
  },
  {
    id: 2,
    nome: 'Arapiraca - 103.3 FM',
    lat: -9.75,
    lng: -36.66,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_ARAPIRACA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_ARAPIRACAAAC.aac',
    frequencia: '103.3 FM',
    status: 'ao vivo',
  },
  {
    id: 3,
    nome: 'Atibaia - 91.9 FM',
    lat: -23.1167,
    lng: -46.55,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_ATIBAIA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_ATIBAIAAAC.aac',
    frequencia: '91.9 FM',
    status: 'ao vivo',
  },
  {
    id: 4,
    nome: 'Belém - 100.9 FM',
    lat: -1.4558,
    lng: -48.4903,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BELEMPARA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BELEMPARAAAC.aac',
    frequencia: '100.9 FM',
    status: 'ao vivo',
  },
  {
    id: 5,
    nome: 'Belo Horizonte - 90.3 FM',
    lat: -19.9167,
    lng: -43.9333,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BH.mp3',
    url_aac: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BHAAC.aac',
    frequencia: '90.3 FM',
    status: 'ao vivo',
  },
  {
    id: 6,
    nome: 'Blumenau - 106.3 FM',
    lat: -26.9189,
    lng: -49.0658,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BLUMENAU.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BLUMENAUAAC.aac',
    frequencia: '106.3 FM',
    status: 'ao vivo',
  },
  {
    id: 7,
    nome: 'Brasília - 88.3 FM',
    lat: -15.7942,
    lng: -47.8822,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BRASILIA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BRASILIAAAC.aac',
    frequencia: '88.3 FM',
    status: 'ao vivo',
  },
  {
    id: 8,
    nome: 'Campina Grande - 104.7 FM',
    lat: -7.2306,
    lng: -35.8811,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CAMPINAGDE.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CAMPINAGDEAAC.aac',
    frequencia: '104.7 FM',
    status: 'ao vivo',
  },
  {
    id: 9,
    nome: 'Campinas - 101.1 FM',
    lat: -22.9009,
    lng: -47.0573,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CAMPINAS.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CAMPINASAAC.aac',
    frequencia: '101.1 FM',
    status: 'ao vivo',
  },
  {
    id: 10,
    nome: 'Capão Bonito - 102.9 FM',
    lat: -24.0058,
    lng: -48.3494,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CAPAOBONITO.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CAPAOBONITOAAC.aac',
    frequencia: '102.9 FM',
    status: 'ao vivo',
  },
  {
    id: 11,
    nome: 'Criciúma - 91.1 FM',
    lat: -28.6775,
    lng: -49.3697,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CRICIUMA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CRICIUMAAAC.aac',
    frequencia: '91.1 FM',
    status: 'ao vivo',
  },
  {
    id: 12,
    nome: 'Cuiabá - 93.3 FM',
    lat: -15.6011,
    lng: -56.0975,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CUIABA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CUIABAAAC.aac',
    frequencia: '93.3 FM',
    status: 'ao vivo',
  },
  {
    id: 13,
    nome: 'Curitiba - 98.3 FM',
    lat: -25.4296,
    lng: -49.2713,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CURITIBA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CURITIBAAAC.aac',
    frequencia: '98.3 FM',
    status: 'ao vivo',
  },
  {
    id: 14,
    nome: 'Fernandópolis - 105.3 FM',
    lat: -20.2839,
    lng: -50.2469,
    url_mp3: '',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_FERNANDOPOAAC.aac',
    frequencia: '105.3 FM',
    status: 'ao vivo',
  },
  {
    id: 15,
    nome: 'Fortaleza - 107.5 FM',
    lat: -3.7319,
    lng: -38.5267,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_FORTA1075.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_FORTA1075AAC.aac',
    frequencia: '107.5 FM',
    status: 'ao vivo',
  },
  {
    id: 16,
    nome: 'Foz do Iguaçu - 91.1 FM',
    lat: -25.5469,
    lng: -54.5881,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_FOZIGUACU.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_FOZIGUACUAAC.aac',
    frequencia: '91.1 FM',
    status: 'ao vivo',
  },
  {
    id: 17,
    nome: 'Goiânia - 106.1 FM',
    lat: -16.6799,
    lng: -49.255,
    url_mp3: '',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_GOIANIAAAC.aac',
    frequencia: '106.1 FM',
    status: 'ao vivo',
  },
  {
    id: 18,
    nome: 'Guaratinguetá - 106.7 FM',
    lat: -22.8167,
    lng: -45.2278,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_GUARATINGUEAAC.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_GUARATINGUEAAC.aac',
    frequencia: '106.7 FM',
    status: 'ao vivo',
  },
  {
    id: 19,
    nome: 'Ji-Paraná - 99.1 FM',
    lat: -10.8853,
    lng: -61.9517,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_JIPARANA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_JIPARANAAAC.aac',
    frequencia: '99.1 FM',
    status: 'ao vivo',
  },
  {
    id: 20,
    nome: 'João Pessoa - 93.7 FM',
    lat: -7.12,
    lng: -34.8811,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_JOAOPESSOA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_JOAOPESSOAAAC.aac',
    frequencia: '93.7 FM',
    status: 'ao vivo',
  },
  {
    id: 21,
    nome: 'Juiz de Fora - 88.9 FM',
    lat: -21.7642,
    lng: -43.3503,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_JUIZDEFORA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_JUIZDEFORAAAC.aac',
    frequencia: '88.9 FM',
    status: 'ao vivo',
  },
  {
    id: 22,
    nome: 'Londrina - 102.9 FM',
    lat: -23.3103,
    lng: -51.1628,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_LONDRIN1029.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_LONDRIN1029AAC.aac',
    frequencia: '102.9 FM',
    status: 'ao vivo',
  },
  {
    id: 23,
    nome: 'Maceió - 98.3 FM',
    lat: -9.6658,
    lng: -35.7353,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_MACEIO.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_MACEIOAAC.aac',
    frequencia: '98.3 FM',
    status: 'ao vivo',
  },
  {
    id: 24,
    nome: 'Manaus - 100.7 FM',
    lat: -3.119,
    lng: -60.0217,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_MANAUS.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_MANAUSAAC.aac',
    frequencia: '100.7 FM',
    status: 'ao vivo',
  },
  {
    id: 25,
    nome: 'Maringá - 97.9 FM',
    lat: -23.4253,
    lng: -51.9386,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_MARINGA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_MARINGAAAC.aac',
    frequencia: '97.9 FM',
    status: 'ao vivo',
  },
  {
    id: 26,
    nome: 'Natal - 103.9 FM',
    lat: -5.7945,
    lng: -35.211,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_NATAL.mp3',
    url_aac: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_NATALAAC.aac',
    frequencia: '103.9 FM',
    status: 'ao vivo',
  },
  {
    id: 27,
    nome: 'Ponta Grossa - 94.7 FM',
    lat: -25.095,
    lng: -50.1619,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_PTAGROSSA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_PTAGROSSAAAC.aac',
    frequencia: '94.7 FM',
    status: 'ao vivo',
  },
  {
    id: 28,
    nome: 'Porto Alegre - 107.1 FM',
    lat: -30.0331,
    lng: -51.23,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_POA.MP3',
    url_aac: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_POAAAC.aac',
    frequencia: '107.1 FM',
    status: 'ao vivo',
  },
  {
    id: 29,
    nome: 'Presidente Prudente - 106.7 FM',
    lat: -22.1276,
    lng: -51.3856,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_PPRUDENTEAAC.aac',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_PPRUDENTEAAC.aac',
    frequencia: '106.7 FM',
    status: 'ao vivo',
  },
  {
    id: 30,
    nome: 'Recife - 97.1 FM',
    lat: -8.0543,
    lng: -34.8813,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_RECIFE971.MP3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_RECIFE971AAC.aac',
    frequencia: '97.1 FM',
    status: 'ao vivo',
  },
  {
    id: 31,
    nome: 'Ribeirão Preto - 91.7 FM',
    lat: -21.1775,
    lng: -47.8103,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_RIBPRETO917.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_RIBPRETO917AAC.aac',
    frequencia: '91.7 FM',
    status: 'ao vivo',
  },
  {
    id: 32,
    nome: 'Rio de Janeiro - 102.1 FM',
    lat: -22.9068,
    lng: -43.1729,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXRIO.mp3',
    url_aac: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXRIOAAC.aac',
    frequencia: '102.1 FM',
    status: 'ao vivo',
  },
  {
    id: 33,
    nome: 'Rio Preto - 107.9 FM',
    lat: -20.82,
    lng: -49.3797,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_RIOPRETO975AAC.aac',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_RIOPRETO975AAC.aac',
    frequencia: '107.9 FM',
    status: 'ao vivo',
  },
  {
    id: 34,
    nome: 'Salvador - 104.3 FM',
    lat: -12.9711,
    lng: -38.5108,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SALVADOR.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SALVADORAAC.aac',
    frequencia: '104.3 FM',
    status: 'ao vivo',
  },
  {
    id: 35,
    nome: 'Santarém - 100.9 FM',
    lat: -2.4431,
    lng: -54.7081,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SANTAREM.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SANTAREMAAC.aac',
    frequencia: '100.9 FM',
    status: 'ao vivo',
  },
  {
    id: 36,
    nome: 'Santos - 106.7 FM',
    lat: -23.9618,
    lng: -46.3322,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SANTOS.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SANTOSAAC.aac',
    frequencia: '106.7 FM',
    status: 'ao vivo',
  },
  {
    id: 37,
    nome: 'Itu - 106.5 FM',
    lat: -23.2641,
    lng: -47.2993,
    url_mp3: '',
    url_aac: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_ITUAAC.aac',
    frequencia: '106.5 FM',
    status: 'ao vivo',
  },
  {
    id: 38,
    nome: 'MIX Oeste Paulista - 88.9 FM',
    lat: -21.9218,
    lng: -50.7356,
    url_mp3: '',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_BASTOSSPAAC.aac',
    frequencia: '88.9 FM',
    status: 'ao vivo',
  },
  {
    id: 39,
    nome: 'Centro Paulista - 106.5 FM',
    lat: -22.3289,
    lng: -48.5505,
    url_mp3: '',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_CPAULISTAAAC.aac',
    frequencia: '106.5 FM',
    status: 'ao vivo',
  },
  {
    id: 40,
    nome: 'São Carlos - 103.3 FM',
    lat: -22.0056,
    lng: -47.8978,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SAOCARLOS.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SAOCARLOSAAC.aac',
    frequencia: '103.3 FM',
    status: 'ao vivo',
  },
  {
    id: 41,
    nome: 'São Paulo - 106.3 FM',
    lat: -23.5505,
    lng: -46.6333,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SAOPAULO.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SAOPAULOAAC.aac',
    frequencia: '106.3 FM',
    status: 'ao vivo',
  },
  {
    id: 42,
    nome: 'Sul de Minas - 104.1 FM',
    lat: -22.315,
    lng: -45.5653,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SULMG.mp3',
    url_aac: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SULMGAAC.aac',
    frequencia: '104.1 FM',
    status: 'ao vivo',
  },
  {
    id: 43,
    nome: 'Sul de Santa Catarina - 103.1 FM',
    lat: -28.6804,
    lng: -49.3699,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SULSC.mp3',
    url_aac: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SULSCAAC.aac',
    frequencia: '103.1 FM',
    status: 'ao vivo',
  },
  {
    id: 44,
    nome: 'Teresina - 91.5 FM',
    lat: -5.0892,
    lng: -42.8019,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_TERESINA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_TERESINAAAC.aac',
    frequencia: '91.5 FM',
    status: 'ao vivo',
  },
  {
    id: 45,
    nome: 'Uberlândia - 106.5 FM',
    lat: -18.9141,
    lng: -48.2749,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_UBERLANDIA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_UBERLANDIAAAC.aac',
    frequencia: '106.5 FM',
    status: 'ao vivo',
  },
  {
    id: 46,
    nome: 'Vale do Paraíba - 94.9 FM',
    lat: -23.2237,
    lng: -45.9009,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_VALEPARAIBA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_VALEPARAIBAAAC.aac',
    frequencia: '94.9 FM',
    status: 'ao vivo',
  },
  {
    id: 47,
    nome: 'Vale Litoral de Santa Catarina - 102.3 FM',
    lat: -26.9339,
    lng: -48.6344,
    url_mp3:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_LITORALSC.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_LITORALSCAAAC.aac',
    frequencia: '102.3 FM',
    status: 'ao vivo',
  },
  {
    id: 48,
    nome: 'Vitória - 106.3 FM',
    lat: -20.3155,
    lng: -40.3128,
    url_mp3: 'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_VITORIA.mp3',
    url_aac:
      'https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_VITORIAAAC.aac',
    frequencia: '106.3 FM',
    status: 'ao vivo',
  },
];

function MixMonitor({ onBack }) {
  const [afiliadas, setAfiliadas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUrl, setCurrentUrl] = useState('');
  const [currentAfiliada, setCurrentAfiliada] = useState(null);
  const [audioLoading, setAudioLoading] = useState(false);
  const [onlineCount, setOnlineCount] = useState(0);
  const [offlineCount, setOfflineCount] = useState(0);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [audioElement, setAudioElement] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  // Referências
  const mapRef = useRef(null);
  const googleMapRef = useRef(null);
  const markersRef = useRef([]);
  const infoWindowsRef = useRef([]);
  const updateTimerRef = useRef(null);
  const activeMarkerRef = useRef(null);
  const activeInfoWindowRef = useRef(null);
  const currentAudioRef = useRef(null); // Ref para o elemento de áudio atual

  // Variáveis para controle da verificação contínua
  const [verificacaoEmAndamento, setVerificacaoEmAndamento] = useState(false);
  const [indiceVerificacaoAtual, setIndiceVerificacaoAtual] = useState(0);
  const verificacaoTimerRef = useRef(null);

  // Função para buscar dados das afiliadas
  const fetchAfiliadas = async () => {
    try {
      console.log('Carregando dados das afiliadas...');

      // Usando os dados estáticos de AFILIADAS
      const afiliadasComStatus = AFILIADAS.map(afiliada => ({
        ...afiliada,
        online: true, // Inicialmente marcamos como online
        lat: parseFloat(afiliada.lat),
        lng: parseFloat(afiliada.lng),
        color: 'green', // Inicialmente todos os marcadores são verdes
      }));

      console.log(`Dados carregados: ${afiliadasComStatus.length} afiliadas`);
      setAfiliadas(afiliadasComStatus);
      setOnlineCount(afiliadasComStatus.length);
      setOfflineCount(0);

      // Se o mapa já estiver carregado, cria os marcadores
      if (googleMapRef.current) {
        console.log('Mapa já carregado, criando marcadores imediatamente');
        setTimeout(() => {
          createMarkers();
        }, 500);
      }
    } catch (error) {
      console.error('Erro ao carregar dados das afiliadas:', error);
      setError('Erro ao carregar dados das rádios');
    } finally {
      setLoading(false);
    }
  };

  // Função para carregar o script do Google Maps
  const loadGoogleMapsScript = () => {
    console.log('Iniciando carregamento do Google Maps');

    if (window.google && window.google.maps) {
      console.log('Google Maps já está carregado');
      initMap();
      return;
    }

    const existingScript = document.getElementById('google-maps-script');
    if (existingScript) {
      console.log('Removendo script antigo do Google Maps');
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = 'google-maps-script';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMapCallback`;
    script.async = true;
    script.defer = true;

    window.initMapCallback = () => {
      console.log('Callback do Google Maps chamado');
      initMap();
    };

    script.onerror = error => {
      console.error('Erro ao carregar script do Google Maps:', error);
      setError('Erro ao carregar o mapa. Por favor, recarregue a página.');
      setLoading(false);
    };

    document.head.appendChild(script);
    console.log('Script do Google Maps adicionado ao documento');
  };

  // Função para inicializar o mapa
  const initMap = () => {
    console.log('Iniciando inicialização do mapa');

    try {
      const mapElement = document.getElementById('map-container');

      if (!mapElement) {
        throw new Error('Elemento do mapa não encontrado');
      }

      console.log('Criando instância do mapa');

      const map = new window.google.maps.Map(mapElement, {
        center: { lat: -15.7942, lng: -47.8822 }, // Centro do Brasil (Brasília)
        zoom: 5,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: true,
        styles: [
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative.neighborhood',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ],
      });

      googleMapRef.current = map;
      mapRef.current = mapElement;
      setMapLoaded(true);
      setLoading(false);

      console.log('Mapa inicializado com sucesso');

      // Cria os marcadores se já tiver dados
      if (afiliadas.length > 0) {
        console.log('Criando marcadores para', afiliadas.length, 'afiliadas');
        setTimeout(() => {
          createMarkers();
        }, 500);
      } else {
        console.log('Sem afiliadas para criar marcadores, aguardando dados');
      }
    } catch (error) {
      console.error('Erro ao inicializar o mapa:', error);
      setError('Erro ao inicializar o mapa: ' + error.message);
      setLoading(false);
    }
  };

  // Função avançada para testar se uma stream está online
  const testStream = async url => {
    if (!url) return false;

    console.log(`Verificando stream: ${url}`);

    // Em dispositivos móveis, retornamos true para evitar verificações desnecessárias
    if (isMobile) {
      console.log('Dispositivo móvel: considerando stream online por padrão');
      return true;
    }

    // Determina o tipo de stream
    const isMP3 = url.includes('.mp3') || url.includes('mp3');
    const isAAC = url.includes('.aac') || url.includes('aac');

    // Função para verificar com fetch
    const checkWithFetch = async () => {
      try {
        const controller = new AbortController();
        // Reduzimos o timeout de 3000ms para 2000ms para verificação mais rápida
        const timeoutId = setTimeout(() => controller.abort(), 2000);

        const response = await fetch(url, {
          method: 'HEAD',
          cache: 'no-store',
          signal: controller.signal,
        });

        clearTimeout(timeoutId);

        // Se o status for 200-299, a stream está online
        return response.ok;
      } catch (error) {
        // Para erros de CORS, tentamos outra abordagem
        if (error.name === 'TypeError' && error.message.includes('CORS')) {
          return checkWithNoCors();
        }
        return false;
      }
    };

    // Função para verificar com no-cors
    const checkWithNoCors = async () => {
      try {
        const controller = new AbortController();
        // Reduzimos o timeout de 3000ms para 2000ms para verificação mais rápida
        const timeoutId = setTimeout(() => controller.abort(), 2000);

        await fetch(url, {
          method: 'HEAD',
          mode: 'no-cors',
          cache: 'no-store',
          signal: controller.signal,
        });

        clearTimeout(timeoutId);

        // Se não houve erro, assumimos que está online
        return true;
      } catch (error) {
        return false;
      }
    };

    // Função para verificar com Audio
    const checkWithAudio = () => {
      return new Promise(resolve => {
        const audio = new Audio();

        // Reduzimos o timeout de 5000ms para 3000ms para verificação mais rápida
        const timeout = setTimeout(() => {
          audio.pause();
          audio.src = '';
          resolve(false);
        }, 3000);

        // Mudamos o nível de prontidão necessário de HAVE_FUTURE_DATA (3) para HAVE_CURRENT_DATA (2)
        audio.oncanplay = () => {
          clearTimeout(timeout);
          audio.pause();
          audio.src = '';
          resolve(true);
        };

        audio.onerror = () => {
          clearTimeout(timeout);
          audio.src = '';
          resolve(false);
        };

        audio.src = url;
        audio.load();
      });
    };

    // Estratégia baseada no tipo de stream
    try {
      if (isMP3 || isAAC) {
        // Para MP3/AAC, tentamos primeiro com Audio
        const audioResult = await checkWithAudio();
        if (audioResult) return true;

        // Se falhar, tentamos com fetch
        return await checkWithFetch();
      } else {
        // Para outros tipos, tentamos fetch primeiro
        const fetchResult = await checkWithFetch();
        if (fetchResult) return true;

        // Se falhar, tentamos com Audio
        return await checkWithAudio();
      }
    } catch (error) {
      console.error(`Erro ao verificar stream ${url}:`, error);
      return false;
    }
  };

  // Função auxiliar para verificar uma afiliada
  const verificarAfiliada = async afiliada => {
    try {
      // Verifica se a afiliada tem URLs válidas
      if (!afiliada.url_mp3 && !afiliada.url_aac) {
        console.warn(`Afiliada ${afiliada.nome} não tem URLs de stream válidas`);
        return { ...afiliada, online: false, color: 'red' };
      }

      // Tenta URLs alternativas se as principais não estiverem definidas
      const mp3Url = afiliada.url_mp3 || afiliada.stream_mp3 || afiliada.mp3_url;
      const aacUrl = afiliada.url_aac || afiliada.stream_aac || afiliada.aac_url;

      // Verifica primeiro o stream MP3
      let mp3Online = false;
      if (mp3Url) {
        mp3Online = await testStream(mp3Url);
      }

      // Se MP3 estiver online, não precisa verificar AAC
      if (mp3Online) {
        return { ...afiliada, online: true, color: 'green' };
      }

      // Se MP3 estiver offline, verifica AAC
      let aacOnline = false;
      if (aacUrl) {
        aacOnline = await testStream(aacUrl);
      }

      // Retorna o status final
      return {
        ...afiliada,
        online: mp3Online || aacOnline,
        color: mp3Online || aacOnline ? 'green' : 'red',
      };
    } catch (error) {
      console.error(`Erro ao verificar afiliada ${afiliada.nome}:`, error);
      return { ...afiliada, online: false, color: 'red' };
    }
  };

  // Função para processar em lotes com limite de concorrência
  const processarEmLotes = async (itens, funcaoProcessamento, tamanhoDeLote = 10) => {
    const resultados = [];

    for (let i = 0; i < itens.length; i += tamanhoDeLote) {
      const lote = itens.slice(i, i + tamanhoDeLote);
      const resultadosLote = await Promise.all(lote.map(item => funcaoProcessamento(item)));
      resultados.push(...resultadosLote);

      // Atualiza o progresso a cada lote
      const progresso = Math.min(100, Math.round(((i + lote.length) / itens.length) * 100));
      console.log(`Progresso: ${progresso}% (${i + lote.length}/${itens.length})`);
    }

    return resultados;
  };

  // Função para verificar todas as streams
  const checkAllStreams = async () => {
    console.log('Iniciando verificação de todas as streams...');

    if (!afiliadas || afiliadas.length === 0) {
      console.log('Não há afiliadas para verificar');
      return;
    }

    setLoading(true);

    try {
      // Cria uma cópia das afiliadas para atualizar
      const afiliadasAtualizadas = [...afiliadas];
      let onlineCount = 0;
      let offlineCount = 0;

      // Verifica todas as streams em paralelo
      const resultados = await Promise.all(
        afiliadasAtualizadas.map(async afiliada => {
          // Verifica se a afiliada tem pelo menos uma URL
          if (!afiliada.url_mp3 && !afiliada.url_aac) {
            return { ...afiliada, online: false };
          }

          // Tenta primeiro MP3, depois AAC
          let isOnline = false;

          if (afiliada.url_mp3) {
            isOnline = await testStream(afiliada.url_mp3);
          }

          if (!isOnline && afiliada.url_aac) {
            isOnline = await testStream(afiliada.url_aac);
          }

          // Retorna a afiliada atualizada com status simplificado
          return {
            ...afiliada,
            online: isOnline,
          };
        })
      );

      // Atualiza as afiliadas com os resultados
      for (let i = 0; i < resultados.length; i++) {
        afiliadasAtualizadas[i] = resultados[i];

        // Atualiza o marcador no mapa
        if (markersRef.current && markersRef.current[i]) {
          const icon = {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: resultados[i].online ? '#4CAF50' : '#f44336', // Verde se online, vermelho se offline
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: '#FFFFFF',
          };
          markersRef.current[i].setIcon(icon);
        }

        // Conta online/offline
        if (resultados[i].online) {
          onlineCount++;
        } else {
          offlineCount++;
        }
      }

      console.log(`Verificação completa: ${onlineCount} online, ${offlineCount} offline`);

      // Atualiza o estado com os novos contadores e afiliadas
      setAfiliadas(afiliadasAtualizadas);
      setOnlineCount(onlineCount);
      setOfflineCount(offlineCount);

      // Se a janela de informações estiver aberta, atualiza seu conteúdo
      if (activeInfoWindowRef.current && activeMarkerRef.current && !currentAudioRef.current) {
        const marcadorIndex = markersRef.current.findIndex(m => m === activeMarkerRef.current);
        if (marcadorIndex >= 0 && afiliadasAtualizadas[marcadorIndex]) {
          activeInfoWindowRef.current.setContent(
            getInfoWindowContent(afiliadasAtualizadas[marcadorIndex], null, false)
          );
        }
      }

      return afiliadasAtualizadas;
    } catch (error) {
      console.error('Erro ao verificar streams:', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para parar a reprodução da stream atual
  const stopStream = () => {
    try {
      console.log('Parando stream atual');

      if (currentAudioRef.current) {
        // Pausa e limpa o áudio
        currentAudioRef.current.pause();
        currentAudioRef.current.src = '';

        // Remove o elemento de áudio do DOM se existir
        if (document.getElementById('audio-player')) {
          document.getElementById('audio-player').remove();
        }

        // Limpa as referências
        currentAudioRef.current = null;
        setAudioElement(null);

        // Limpa o estado da afiliada atual
        setCurrentAfiliada(null);

        // Atualiza a janela de informações se estiver aberta
        if (activeInfoWindowRef.current && activeMarkerRef.current) {
          const marcadorIndex = markersRef.current.findIndex(m => m === activeMarkerRef.current);
          if (marcadorIndex >= 0 && afiliadas[marcadorIndex]) {
            console.log('Atualizando janela de informações após parar áudio');
            // Pequeno atraso para garantir que o estado foi atualizado
            setTimeout(() => {
              if (activeInfoWindowRef.current) {
                activeInfoWindowRef.current.setContent(
                  getInfoWindowContent(afiliadas[marcadorIndex], null, false)
                );
              }
            }, 50);
          }
        }
      }
    } catch (error) {
      console.error('Erro ao parar stream:', error);
    }
  };

  // Função para obter o conteúdo da janela de informações
  const getInfoWindowContent = (afiliada, streamUrl, isLoading) => {
    // Verifica se a afiliada tem pelo menos um formato disponível
    const hasStream = afiliada.url_mp3 || afiliada.url_aac;

    // Verifica se está reproduzindo usando a ref e o estado
    const isPlaying =
      currentAfiliada &&
      parseInt(currentAfiliada.id, 10) === parseInt(afiliada.id, 10) &&
      currentAudioRef.current;

    // Garante que o ID da afiliada seja um número
    const afiliadaId = parseInt(afiliada.id, 10);

    console.log(
      `Gerando conteúdo para afiliada ${afiliada.nome}, isPlaying: ${isPlaying}, audioRef: ${currentAudioRef.current ? 'existe' : 'não existe'}`
    );

    // Cria os medidores de áudio (15 barras)
    const createAudioMeters = () => {
      let meters = '';
      for (let i = 1; i <= 15; i++) {
        meters += `<div class="meter-bar">
          <div class="meter-level"></div>
        </div>`;
      }
      return meters;
    };

    let content = `
      <div class="info-window">
        <h3><img src="/img/rede.png" alt="Rede" class="station-icon" /> ${afiliada.nome}</h3>
        <p>Frequência: ${afiliada.frequencia}</p>
    `;

    // Adiciona o status da transmissão (ao vivo)
    if (afiliada.status === 'ao vivo') {
      content += `
        <div class="live-status">
          <span class="live-indicator"></span>
          <span class="live-text">Ao Vivo</span>
        </div>
      `;
    }

    // Novo player com medidores de áudio
    content += `<div class="player-container" id="player-container-${afiliadaId}">`;

    if (isLoading) {
      content += `
        <div class="player-loading">
          <span class="loading-spinner-small"></span> Carregando áudio...
        </div>
      `;
    } else {
      // Versão móvel simplificada com player HTML5 nativo
      if (isMobile) {
        // Determina qual URL usar (primeiro tenta MP3, depois AAC)
        const mp3Url = afiliada.url_mp3 || '';
        const aacUrl = afiliada.url_aac || '';

        if (isPlaying) {
          // Se já está tocando, mostra controles simples
          content += `
            <div class="mobile-player-controls">
              <button class="mobile-stop-button" onclick="window.stopCurrentStream()">Parar</button>
              <div class="mobile-playing-status">Reproduzindo</div>
            </div>
          `;
        } else if (hasStream) {
          // Se não está tocando, mostra o player HTML5 nativo
          content += `
            <div class="mobile-player">
              <audio controls style="width: 100%; max-width: 250px;" preload="none" playsinline webkit-playsinline>
                ${mp3Url ? `<source src="${mp3Url}" type="audio/mpeg">` : ''}
                ${aacUrl ? `<source src="${aacUrl}" type="audio/aac">` : ''}
                Seu navegador não suporta o elemento de áudio.
              </audio>
            </div>
          `;
        } else {
          // Mensagem quando não há stream disponível
          content += `
            <p class="stream-unavailable">Stream indisponível no momento</p>
          `;
        }
      } else {
        // Versão desktop original com medidores de áudio
        content += `
          <div class="audio-player">
            <div class="station-info">
              <div class="station-logo">
                <img src="/img/rede.png" alt="Logo" />
              </div>
              <div class="station-details">
                <p class="station-name">${afiliada.nome}</p>
                <p class="station-frequency">${afiliada.frequencia}</p>
              </div>
            </div>
        `;

        // Só mostra os medidores de áudio se estiver realmente reproduzindo
        if (isPlaying) {
          content += `
            <div class="audio-meters">
              ${createAudioMeters()}
              <div class="audio-glow"></div>
            </div>
            
            <div class="player-controls">
              <button class="player-button stop-button" onclick="window.stopCurrentStream()">■</button>
              <div class="player-status">
                <div class="playing-indicator">
                  <div class="playing-dot"></div>
                  <div class="playing-dot"></div>
                  <div class="playing-dot"></div>
                </div>
                <span>Reproduzindo</span>
              </div>
            </div>
            
            <div class="volume-control">
              <span class="volume-icon">🔊</span>
              <div class="volume-slider">
                <div class="volume-level"></div>
                <div class="volume-knob"></div>
              </div>
            </div>
          `;
        } else if (hasStream) {
          // Interface simplificada quando não está tocando - apenas o botão de play
          content += `
            <div class="player-controls">
              <button class="player-button play-button" onclick="window.playStream(${afiliadaId})">▶</button>
              <div class="player-status">
                <span>Pronto para reproduzir</span>
              </div>
            </div>
          `;
        } else {
          // Mensagem quando não há stream disponível
          content += `
            <p class="stream-unavailable">Stream indisponível no momento</p>
          `;
        }

        content += '</div>'; // Fecha audio-player
      }
    }

    content += '</div></div>'; // Fecha player-container e info-window
    return content;
  };

  // Função para criar marcadores no mapa
  const createMarkers = () => {
    console.log('Criando marcadores no mapa');

    if (!googleMapRef.current || !afiliadas || afiliadas.length === 0) {
      console.log('Mapa ou afiliadas não disponíveis para criar marcadores');
      return;
    }

    // Limpa marcadores existentes
    if (markersRef.current.length > 0) {
      markersRef.current.forEach(marker => {
        if (marker) marker.setMap(null);
      });
      markersRef.current = [];
    }

    // Cria novos marcadores
    const bounds = new window.google.maps.LatLngBounds();
    const newMarkers = [];

    afiliadas.forEach((afiliada, index) => {
      // Verifica se as coordenadas são válidas
      if (!afiliada.lat || !afiliada.lng) {
        console.log(`Afiliada ${afiliada.nome} não possui coordenadas válidas`);
        return;
      }

      const lat = parseFloat(afiliada.lat);
      const lng = parseFloat(afiliada.lng);

      // Verifica se as coordenadas são números válidos
      if (isNaN(lat) || isNaN(lng)) {
        console.log(
          `Afiliada ${afiliada.nome} possui coordenadas inválidas: ${afiliada.lat}, ${afiliada.lng}`
        );
        return;
      }

      const position = { lat, lng };

      // Determina a cor do marcador
      // Em dispositivos móveis, consideramos todas as afiliadas como online por padrão
      const isOnline = isMobile ? true : afiliada.online;

      // Cria o marcador
      const marker = new window.google.maps.Marker({
        position,
        map: googleMapRef.current,
        title: afiliada.nome,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 12,
          fillColor: isOnline ? '#4CAF50' : '#f44336', // Verde se online, vermelho se offline
          fillOpacity: 0.9,
          strokeWeight: 2,
          strokeColor: '#FFFFFF',
        },
        animation: window.google.maps.Animation.DROP,
        optimized: true,
      });

      // Adiciona evento de clique
      marker.addListener('click', () => {
        // Fecha a janela de informações ativa se existir
        if (activeInfoWindowRef.current) {
          activeInfoWindowRef.current.close();

          // Se o marcador clicado for o mesmo que já estava ativo, apenas fecha a janela
          if (activeMarkerRef.current === marker) {
            activeMarkerRef.current = null;
            activeInfoWindowRef.current = null;
            stopStream();
            return;
          }
        }

        // Para qualquer stream que esteja tocando
        stopStream();

        // Define o marcador ativo
        activeMarkerRef.current = marker;

        // Cria o conteúdo da janela de informações
        const content = getInfoWindowContent(afiliada, null, false);

        // Cria e abre a janela de informações
        const infoWindow = new window.google.maps.InfoWindow({
          content,
          maxWidth: 300,
        });

        // Armazena a referência da janela de informações ativa
        activeInfoWindowRef.current = infoWindow;

        // Adiciona referência do marcador à janela de informações para uso posterior
        infoWindow.marker = marker;

        infoWindow.open(googleMapRef.current, marker);

        // Adiciona evento para fechar a janela
        infoWindow.addListener('closeclick', () => {
          console.log('Janela de informações fechada, parando áudio');
          stopStream();
          activeMarkerRef.current = null;
          activeInfoWindowRef.current = null;
        });
      });

      // Adiciona o marcador à lista
      newMarkers.push(marker);

      // Adiciona a posição aos limites do mapa
      bounds.extend(position);
    });

    // Atualiza a referência dos marcadores
    markersRef.current = newMarkers;

    // Ajusta o zoom para mostrar todos os marcadores
    if (newMarkers.length > 0) {
      try {
        googleMapRef.current.fitBounds(bounds);

        // Limita o zoom máximo para não aproximar demais
        const listener = window.google.maps.event.addListenerOnce(
          googleMapRef.current,
          'idle',
          () => {
            if (googleMapRef.current.getZoom() > 5) {
              googleMapRef.current.setZoom(5);
            }
          }
        );
      } catch (error) {
        console.error('Erro ao ajustar zoom:', error);
      }
    }
  };

  // Função para verificar afiliada em segundo plano (com otimização para mobile)
  const verificarAfiliadaEmSegundoPlano = async index => {
    // Em dispositivos móveis, desativamos a verificação contínua em segundo plano
    if (isMobile) {
      console.log('Dispositivo móvel: verificação contínua em segundo plano desativada');
      return;
    }

    if (!afiliadas || afiliadas.length === 0 || !markersRef.current) {
      console.log('Não há afiliadas para verificar em segundo plano');
      return;
    }

    // Verifica se o índice é válido
    if (index >= afiliadas.length) {
      console.log('Ciclo de verificação completo, atualizando contadores...');

      // Usa a função dedicada para atualizar os contadores de forma segura
      atualizarContadores();

      // Reinicia o ciclo de verificação após um intervalo maior
      setTimeout(() => verificarAfiliadaEmSegundoPlano(0), 10000);
      return;
    }

    try {
      // Verifica se a afiliada existe no índice especificado
      if (!afiliadas[index]) {
        console.error(`Afiliada no índice ${index} não encontrada`);
        // Pula para a próxima afiliada
        setTimeout(() => verificarAfiliadaEmSegundoPlano(index + 1), 2000);
        return;
      }

      // Pula a verificação se esta afiliada estiver sendo reproduzida atualmente
      if (currentAfiliada && currentAfiliada.id === afiliadas[index].id) {
        console.log(
          `Afiliada ${afiliadas[index].nome} está sendo reproduzida, pulando verificação`
        );
        setTimeout(() => verificarAfiliadaEmSegundoPlano(index + 1), 2000);
        return;
      }

      const afiliada = afiliadas[index];
      console.log(
        `Verificando afiliada em segundo plano: ${afiliada.nome} (${index + 1}/${afiliadas.length})`
      );

      // Verifica se a afiliada tem pelo menos uma URL
      if (!afiliada.url_mp3 && !afiliada.url_aac) {
        console.log(`Afiliada ${afiliada.nome} não tem URLs de stream`);
        setTimeout(() => verificarAfiliadaEmSegundoPlano(index + 1), 2000);
        return;
      }

      // Tenta primeiro MP3, depois AAC
      let isOnline = false;

      if (afiliada.url_mp3) {
        console.log(`Verificando stream: ${afiliada.url_mp3}`);
        isOnline = await testStream(afiliada.url_mp3);
      }

      if (!isOnline && afiliada.url_aac) {
        console.log(`Verificando stream: ${afiliada.url_aac}`);
        isOnline = await testStream(afiliada.url_aac);
      }

      const statusMudou = afiliada.online !== isOnline;

      // Atualiza o estado apenas se o status mudou
      if (statusMudou) {
        console.log(
          `Status da afiliada ${afiliada.nome} mudou: ${afiliada.online ? 'online' : 'offline'} -> ${isOnline ? 'online' : 'offline'}`
        );

        // Verifica se o marcador ainda existe
        if (markersRef.current && markersRef.current[index]) {
          const icon = {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: isOnline ? '#4CAF50' : '#f44336', // Verde se online, vermelho se offline
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: '#FFFFFF',
          };
          markersRef.current[index].setIcon(icon);
        }

        // Atualiza o estado da afiliada sem alterar os contadores
        setAfiliadas(prevAfiliadas => {
          // Verifica se o array ainda tem o mesmo tamanho
          if (index >= prevAfiliadas.length) {
            console.error(`Índice ${index} fora dos limites do array de afiliadas`);
            return prevAfiliadas;
          }

          const novasAfiliadas = [...prevAfiliadas];
          novasAfiliadas[index] = {
            ...afiliada,
            online: isOnline,
          };
          return novasAfiliadas;
        });

        // Se a janela de informações estiver aberta para esta afiliada, atualiza seu conteúdo
        // Mas apenas se não estiver reproduzindo áudio
        if (
          activeInfoWindowRef.current &&
          activeMarkerRef.current === markersRef.current[index] &&
          !currentAudioRef.current
        ) {
          activeInfoWindowRef.current.setContent(
            getInfoWindowContent({ ...afiliada, online: isOnline }, null, false)
          );
        }
      }
    } catch (error) {
      console.error(`Erro ao verificar afiliada ${index}:`, error);
    }

    // Continua para a próxima afiliada após um intervalo
    setTimeout(() => verificarAfiliadaEmSegundoPlano(index + 1), 2000);
  };

  // Função para atualizar os contadores de forma segura
  const atualizarContadores = () => {
    if (!afiliadas || afiliadas.length === 0) return;

    // Conta as afiliadas online e offline diretamente dos marcadores
    let online = 0;
    let offline = 0;

    // Usa os marcadores para determinar o status real
    if (markersRef.current && markersRef.current.length > 0) {
      markersRef.current.forEach(marker => {
        if (marker) {
          // Verifica a cor do marcador para determinar se está online
          const icon = marker.getIcon();
          if (icon && icon.fillColor === '#4CAF50') {
            online++;
          } else {
            offline++;
          }
        }
      });
    } else {
      // Fallback para contar baseado no estado das afiliadas
      afiliadas.forEach(afiliada => {
        if (afiliada.online === true) {
          online++;
        } else {
          offline++;
        }
      });
    }

    console.log(
      `Atualizando contadores baseado nos marcadores: ${online} online, ${offline} offline`
    );

    // Atualiza os contadores de uma só vez
    setOnlineCount(online);
    setOfflineCount(offline);
  };

  // Efeito para inicializar o componente
  useEffect(() => {
    console.log('MixMonitor montado');

    // Define a função global para parar a stream
    window.stopCurrentStream = () => {
      console.log('Função global stopCurrentStream chamada');
      console.log(
        'Estado atual do áudio (ref):',
        currentAudioRef.current ? 'Existe' : 'Não existe'
      );
      console.log('Estado atual da afiliada:', currentAfiliada ? currentAfiliada.nome : 'Nenhuma');
      stopStream();
    };

    // Configura a função global para reproduzir uma stream
    window.playStream = afiliadaId => {
      try {
        console.log(`Tentando reproduzir afiliada ID ${afiliadaId}`);

        // Para qualquer stream que esteja tocando
        stopStream();

        // Busca a afiliada pelo ID usando o array AFILIADAS estático em vez do estado
        const afiliada = AFILIADAS.find(a => parseInt(a.id, 10) === parseInt(afiliadaId, 10));
        if (!afiliada) {
          console.error(`Afiliada com ID ${afiliadaId} não encontrada no array estático`);

          // Tenta buscar no estado atual como fallback
          const afiliadaNoEstado = afiliadas.find(
            a => parseInt(a.id, 10) === parseInt(afiliadaId, 10)
          );
          if (!afiliadaNoEstado) {
            console.error(`Afiliada com ID ${afiliadaId} também não encontrada no estado`);
            return;
          }

          console.log(`Afiliada encontrada no estado: ${afiliadaNoEstado.nome}`);
          return reproduzirStream(afiliadaNoEstado);
        }

        console.log(`Afiliada encontrada: ${afiliada.nome}`);
        return reproduzirStream(afiliada);
      } catch (error) {
        console.error('Erro ao reproduzir stream:', error);
      }
    };

    // Função interna para reproduzir a stream
    const reproduzirStream = afiliada => {
      // Para qualquer stream que esteja tocando
      stopStream();

      // Determina qual URL usar (primeiro tenta MP3, depois AAC)
      const streamUrl = afiliada.url_mp3 || afiliada.url_aac;
      const format = afiliada.url_mp3 ? 'mp3' : 'aac';

      if (!streamUrl) {
        console.error('Nenhuma URL de stream disponível para esta afiliada');
        return;
      }

      console.log(`Iniciando reprodução para: ${afiliada.nome} - URL: ${streamUrl}`);

      // Remove qualquer elemento de áudio existente
      if (document.getElementById('audio-player')) {
        document.getElementById('audio-player').remove();
      }

      // Cria um novo elemento de áudio
      const audio = document.createElement('audio');
      audio.id = 'audio-player';

      // Otimizações para dispositivos móveis
      if (isMobile) {
        console.log('Usando configuração otimizada para dispositivo móvel');
        // Em dispositivos móveis, não carregamos nada até que o usuário interaja
        audio.preload = 'none';
        // Atributos específicos para iOS
        audio.setAttribute('playsinline', '');
        audio.setAttribute('webkit-playsinline', '');
        // Não precisamos de crossOrigin em dispositivos móveis
      } else {
        // Em desktop, mantemos o crossOrigin para o AudioSpectrum funcionar
        audio.crossOrigin = 'anonymous';
      }

      document.body.appendChild(audio);

      // Define a afiliada atual antes de atualizar a UI
      setCurrentAfiliada({
        ...afiliada,
        format: format,
      });

      // Armazena a referência do áudio atual na ref
      currentAudioRef.current = audio;
      setAudioElement(audio);

      // Atualiza o conteúdo da janela de informações para mostrar o player
      setTimeout(() => {
        if (activeInfoWindowRef.current) {
          console.log('Atualizando janela para mostrar carregamento');
          activeInfoWindowRef.current.setContent(getInfoWindowContent(afiliada, streamUrl, true));
        }
      }, 50);

      // Configura os eventos do áudio
      audio.oncanplay = () => {
        console.log('Stream pronto para reprodução:', streamUrl);

        // Atualiza a UI para mostrar que está reproduzindo
        setTimeout(() => {
          if (activeInfoWindowRef.current) {
            console.log('Atualizando janela para mostrar reprodução');
            activeInfoWindowRef.current.setContent(
              getInfoWindowContent(afiliada, streamUrl, false)
            );
          }
        }, 50);

        // Tenta reproduzir o áudio
        const playPromise = audio.play();

        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.error('Erro ao iniciar reprodução:', error);
            // Tenta novamente após interação do usuário
            if (error.name === 'NotAllowedError') {
              console.log('Reprodução automática bloqueada pelo navegador');
            }
          });
        }
      };

      audio.onerror = e => {
        console.error('Erro ao carregar stream:', e);

        // Em dispositivos móveis, tenta o formato alternativo automaticamente
        if (isMobile) {
          if (afiliada.url_mp3 && format === 'aac') {
            console.log('Dispositivo móvel: tentando formato MP3 como fallback');
            reproduzirStream({
              ...afiliada,
              url_aac: null, // Força o uso do MP3
            });
            return;
          }

          if (afiliada.url_aac && format === 'mp3') {
            console.log('Dispositivo móvel: tentando formato AAC como fallback');
            reproduzirStream({
              ...afiliada,
              url_mp3: null, // Força o uso do AAC
            });
            return;
          }
        }

        if (activeInfoWindowRef.current) {
          activeInfoWindowRef.current.setContent(getInfoWindowContent(afiliada, null, false));
        }

        // Limpa as referências em caso de erro
        currentAudioRef.current = null;
        setAudioElement(null);

        // Remove o elemento de áudio do DOM
        if (document.getElementById('audio-player')) {
          document.getElementById('audio-player').remove();
        }
      };

      // Configura a URL e inicia o carregamento
      audio.src = streamUrl;

      // Em dispositivos móveis, não chamamos load() para evitar carregamento desnecessário
      // O usuário iniciará a reprodução através do controle nativo
      if (!isMobile) {
        audio.load();
      }
    };

    // Adiciona um listener global para o evento beforeunload para parar o áudio quando a página for fechada
    const handleBeforeUnload = () => {
      stopStream();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Carrega o script do Google Maps e busca as afiliadas
    loadGoogleMapsScript();
    fetchAfiliadas();

    // Configura a verificação inicial e contínua
    const verificacaoInicialTimer = setTimeout(() => {
      console.log('Iniciando verificação inicial');

      // Em dispositivos móveis, pulamos a verificação inicial completa
      if (isMobile) {
        console.log('Dispositivo móvel: pulando verificação inicial completa');
        // Apenas atualiza os contadores
        atualizarContadores();
      } else {
        // Para desktop, mantemos a verificação completa
        checkAllStreams().then(() => {
          console.log('Verificação inicial concluída');

          // Garante que os contadores estejam corretos após a verificação inicial
          atualizarContadores();

          // Após a verificação inicial, inicia a verificação contínua em segundo plano
          const verificacaoContinuaTimer = setTimeout(() => {
            console.log('Iniciando verificação contínua em segundo plano');
            verificarAfiliadaEmSegundoPlano(0);
          }, 5000);

          // Salva a referência do timer para limpeza
          verificacaoTimerRef.current = verificacaoContinuaTimer;
        });
      }
    }, 2000);

    // Limpeza ao desmontar o componente
    return () => {
      console.log('MixMonitor desmontado');

      // Remove a função global
      window.stopCurrentStream = null;
      window.removeEventListener('beforeunload', handleBeforeUnload);

      // Para a stream atual
      stopStream();

      // Limpa os timers
      clearTimeout(verificacaoInicialTimer);
      if (verificacaoTimerRef.current) {
        clearTimeout(verificacaoTimerRef.current);
      }

      // Limpa os marcadores
      if (markersRef.current) {
        markersRef.current.forEach(marker => {
          if (marker) marker.setMap(null);
        });
        markersRef.current = [];
      }
    };
  }, []);

  // Efeito para criar os marcadores quando os dados das afiliadas são carregados
  useEffect(() => {
    if (afiliadas.length > 0 && googleMapRef.current) {
      console.log('Afiliadas carregadas, criando marcadores');
      createMarkers();

      // Em dispositivos móveis, pulamos a verificação inicial completa
      if (!isMobile) {
        // Inicia a verificação inicial completa apenas para desktop
        setTimeout(() => {
          checkAllStreams();

          // Após a verificação inicial, inicia a verificação contínua em segundo plano
          setTimeout(() => {
            verificarAfiliadaEmSegundoPlano(0);
          }, 5000);
        }, 2000);
      }

      // Adiciona um listener para o evento de clique no mapa para fechar a janela de informações
      googleMapRef.current.addListener('click', () => {
        if (activeInfoWindowRef.current) {
          console.log('Clique no mapa detectado, fechando janela de informações');
          activeInfoWindowRef.current.close();
          stopStream();
          activeMarkerRef.current = null;
          activeInfoWindowRef.current = null;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afiliadas.length, googleMapRef.current]);

  // Efeito para limpar o áudio quando o componente for desmontado
  useEffect(() => {
    return () => {
      stopStream();

      // Garante que o elemento de áudio seja removido do DOM
      if (document.getElementById('audio-player')) {
        document.getElementById('audio-player').remove();
      }
    };
  }, []);

  // Efeito para detectar dispositivo móvel
  useEffect(() => {
    // Detecta se é um dispositivo móvel
    const mobile = isMobileDevice();
    setIsMobile(mobile);
    console.log('Dispositivo móvel detectado:', mobile);

    // Disponibiliza a função globalmente para uso em outras partes do código
    window.isMobileDevice = mobile;

    // Adiciona listener para redimensionamento da janela
    const handleResize = () => {
      const newMobile = isMobileDevice();
      if (newMobile !== isMobile) {
        setIsMobile(newMobile);
        window.isMobileDevice = newMobile;
        console.log('Status de dispositivo móvel atualizado:', newMobile);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <div className="mix-monitor-container">
      <div className="mix-monitor-header">
        <div className="header-content">
          <h2>
            <span className="tech-icon">
              <img src="/img/rede.png" alt="Rede" className="header-icon" />
            </span>
            Mix Monitor
            <span className="tech-badge">v2.0</span>
          </h2>
          <p className="header-subtitle">Sistema de monitoramento em tempo real</p>
        </div>
        <div className="status-counters">
          <div className="online-counter">Online: {onlineCount}</div>
          <div className="offline-counter">Offline: {offlineCount}</div>
        </div>
      </div>

      <div id="map-container" className="map-container"></div>

      <div className="mix-monitor-footer">
        <button className="update-button-main" onClick={checkAllStreams} disabled={loading}>
          <span className="button-icon">↻</span>
          {loading ? 'Atualizando...' : 'Atualizar Agora'}
        </button>
        <button className="back-button" onClick={onBack}>
          <span className="button-icon">←</span>
          Voltar
        </button>
      </div>

      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Verificando streams...</p>
        </div>
      )}

      {error && (
        <div className="error-container">
          <p>{error}</p>
          <button onClick={() => window.location.reload()}>Recarregar</button>
        </div>
      )}

      {/* Componente AudioSpectrum só é renderizado em dispositivos não-móveis */}
      {audioElement && currentAfiliada && !isMobile && (
        <div
          id="audio-spectrum-container"
          style={{
            position: 'fixed',
            bottom: '70px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            width: '300px',
            height: '80px',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: '10px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 0 15px rgba(0,0,0,0.5)',
            border: '1px solid rgba(255,255,255,0.1)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '5px',
              padding: '0 5px',
            }}
          >
            <span
              style={{
                color: 'white',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {currentAfiliada.nome}
            </span>
            <span
              style={{
                color: '#f44336',
                fontSize: '12px',
              }}
            >
              {currentAfiliada.frequencia}
            </span>
          </div>

          <AudioSpectrum
            id="audio-spectrum"
            height={40}
            width={280}
            audioId={'audio-player'}
            capColor={'rgba(255,255,255,0.5)'}
            capHeight={2}
            meterWidth={3}
            meterCount={40}
            meterColor={[
              { stop: 0, color: '#1E88E5' },
              { stop: 0.5, color: '#26A69A' },
              { stop: 1, color: '#E53935' },
            ]}
            gap={4}
          />
        </div>
      )}

      {/* Versão simplificada para dispositivos móveis */}
      {audioElement && currentAfiliada && isMobile && (
        <div
          id="mobile-player-container"
          style={{
            position: 'fixed',
            bottom: '70px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            width: '90%',
            maxWidth: '300px',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: '10px',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: '0 0 15px rgba(0,0,0,0.5)',
            border: '1px solid rgba(255,255,255,0.1)',
          }}
        >
          <div>
            <div style={{ color: 'white', fontSize: '14px', fontWeight: 'bold' }}>
              {currentAfiliada.nome}
            </div>
            <div style={{ color: '#f44336', fontSize: '12px' }}>{currentAfiliada.frequencia}</div>
          </div>
          <button
            onClick={stopStream}
            style={{
              background: '#f44336',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '36px',
              height: '36px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
            }}
          >
            ■
          </button>
        </div>
      )}
    </div>
  );
}

export default MixMonitor;
