import React from 'react';
import '../styles/AgosTime.css';

const AgosTime = ({ onBack }) => {
  const handleDownload = () => {
    window.location.href = '/download/Agostime v2.rar';
  };

  return (
    <div className="agostime-container">
      <div className="agostime-header">
        <button className="back-button" onClick={onBack}>
          Voltar
        </button>
        <h2>Agostime</h2>
      </div>

      <div className="agostime-content">
        <div className="agostime-hero">
          <div className="agostime-title-container">
            <h1 className="modern-title">Agostime</h1>
            <div className="agostime-subtitle">Sincronização de Tempo de Alta Precisão</div>
            <div className="hero-description">
              <p>
                O Agostime é um software avançado de sincronização de tempo que garante máxima
                precisão ao ajustar automaticamente o horário dos dispositivos de acordo com os
                servidores NTP mais próximos, como o NTP.br.
              </p>
              <p>
                Projetado especialmente para rádios e emissoras de TV, ele assegura que todos os
                equipamentos operem com o horário perfeitamente alinhado, eliminando qualquer
                discrepância que possa comprometer a qualidade da transmissão.
              </p>
            </div>
            <div className="hero-cta">
              <button className="hero-button download-button" onClick={handleDownload}>
                <span className="pulse"></span>
                Download
              </button>
            </div>
          </div>

          <div className="agostime-image-container">
            <div className="image-overlay">
              <div className="overlay-dot top-left"></div>
              <div className="overlay-dot top-right"></div>
              <div className="overlay-dot bottom-left"></div>
              <div className="overlay-dot bottom-right"></div>
            </div>
            <img
              src="/img/agostime.png"
              alt="Agostime - Software de Sincronização de Tempo"
              className="agostime-image"
            />
          </div>
        </div>

        <div className="agostime-features">
          <div className="feature-card">
            <div className="feature-icon">
              <span className="emoji-icon">⏱️</span>
            </div>
            <h3>Precisão Máxima</h3>
            <p>
              Ajusta automaticamente o horário dos dispositivos de acordo com os servidores NTP mais
              próximos, como o NTP.br.
            </p>
          </div>

          <div className="feature-card">
            <div className="feature-icon">
              <span className="emoji-icon">📡</span>
            </div>
            <h3>Ideal para Broadcast</h3>
            <p>
              Projetado especialmente para rádios e emissoras de TV, assegura que todos os
              equipamentos operem com o horário perfeitamente alinhado.
            </p>
          </div>

          <div className="feature-card">
            <div className="feature-icon">
              <span className="emoji-icon">💰</span>
            </div>
            <h3>Economia</h3>
            <p>
              Desenvolvido internamente, eliminando qualquer custo com licenciamento ou aquisição de
              softwares de terceiros.
            </p>
          </div>
        </div>

        <div className="agostime-description">
          <h2>Sobre o Agostime</h2>

          <div className="description-grid">
            <div className="description-content">
              <p>
                O Agostime é um software avançado de sincronização de tempo que garante máxima
                precisão ao ajustar automaticamente o horário dos dispositivos de acordo com os
                servidores NTP mais próximos, como o NTP.br. Projetado especialmente para rádios e
                emissoras de TV, ele assegura que todos os equipamentos operem com o horário
                perfeitamente alinhado, eliminando qualquer discrepância que possa comprometer a
                qualidade da transmissão.
              </p>

              <p>
                Com a implementação do Agostime, dois equipamentos obsoletos foram descontinuados,
                tornando o processo de sincronização mais eficiente, moderno e automatizado. Essa
                inovação reduz a necessidade de hardware especializado, proporcionando uma solução
                mais ágil e confiável para o controle de tempo em ambientes profissionais.
              </p>

              <p>
                Além disso, uma grande vantagem do Agostime é que foi desenvolvido por mim,
                eliminando qualquer custo com licenciamento ou aquisição de softwares de terceiros.
              </p>
            </div>

            <div className="tech-specs">
              <div className="tech-spec-title">Especificações Técnicas</div>
              <ul className="tech-spec-list">
                <li>
                  <span>Precisão:</span> ±1ms
                </li>
                <li>
                  <span>Protocolo:</span> NTP v4
                </li>
                <li>
                  <span>Compatibilidade:</span> Windows 7/10/11
                </li>
                <li>
                  <span>Requisitos:</span> .NET Framework 4.5+
                </li>
                <li>
                  <span>Atualização:</span> Automática
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgosTime;
