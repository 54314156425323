import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import MixMonitor from './components/MixMonitor';
import AgosTime from './components/AgosTime';
import Roadmap from './components/Roadmap';
import RoadmapAdmin from './components/RoadmapAdmin';

function App() {
  // --------------------------
  // ESTADOS
  // --------------------------
  const [query, setQuery] = useState('');
  const [chatHistory, setChatHistory] = useState(() => {
    const storedChat = sessionStorage.getItem('chatHistory');
    return storedChat ? JSON.parse(storedChat) : [];
  });
  const [isTyping, setIsTyping] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(() => localStorage.getItem('isLoggedIn') === 'true');
  const [showLogin, setShowLogin] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showVitrine, setShowVitrine] = useState(false);
  const [activeApp, setActiveApp] = useState(null);
  const [showRBIIframe, setShowRBIIframe] = useState(false);
  const [username, setUsername] = useState(() => localStorage.getItem('username') || '');

  // ---------------------------
  // REFERÊNCIAS
  // ---------------------------
  const userInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const loginButtonRef = useRef(null);
  const chatInputRef = useRef(null);

  // ---------------------------
  // LOGIN
  // ---------------------------
  // Foca o input de usuário ao abrir o login
  useEffect(() => {
    if (showLogin && userInputRef.current) {
      userInputRef.current.focus();
    }
  }, [showLogin]);

  // Fecha login ao pressionar Enter
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      loginButtonRef.current.click();
    }
  };

  const toggleLogin = () => {
    setShowLogin(prev => !prev);
  };

  const handleLogin = () => {
    const username = userInputRef.current.value;
    const password = passwordInputRef.current.value;

    // Verificação especial para o usuário Fabio com senha fixa
    if (username === 'Fabio' && password === 'senha321') {
      localStorage.setItem('username', username);
      localStorage.setItem('isLoggedIn', 'true');
      setUsername(username);
      setIsLoggedIn(true);
      setShowLogin(false);
      setShowVitrine(true);
      return;
    }

    fetch('https://mix-gpt-server.vercel.app/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          localStorage.setItem('username', username);
          localStorage.setItem('isLoggedIn', 'true');
          setUsername(username);
          setIsLoggedIn(true);
          setShowLogin(false);
          setShowVitrine(true);
        } else {
          alert('Erro no login: ' + data.message);
        }
      })
      .catch(error => {
        console.error('Erro ao tentar login:', error);
        alert('Erro ao tentar login.');
      });
  };

  // ---------------------------
  // EFEITOS INICIAIS
  // ---------------------------
  // Limpa o chat ao carregar a página
  useEffect(() => {
    sessionStorage.removeItem('chatHistory');
    setChatHistory([]);
  }, []);

  // Sincroniza estado de login
  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    const storedUsername = localStorage.getItem('username') || '';
    setIsLoggedIn(loggedInStatus);
    setUsername(storedUsername);
    if (loggedInStatus) {
      setShowVitrine(true);
    }
  }, []);

  // Mantém scroll no fim do histórico do chat
  useEffect(() => {
    const chatHistoryDiv = document.querySelector('.chat-history');
    if (chatHistoryDiv) {
      chatHistoryDiv.scrollTop = chatHistoryDiv.scrollHeight;
    }
  }, [chatHistory]);

  // Fecha o chat ao pressionar "Escape" globalmente
  useEffect(() => {
    const handleGlobalKeyDown = event => {
      if (event.key === 'Escape' && showChat) {
        closeChat();
      }
    };

    window.addEventListener('keydown', handleGlobalKeyDown);
    return () => window.removeEventListener('keydown', handleGlobalKeyDown);
  }, [showChat]);

  // Salva o histórico de chat no sessionStorage sempre que atualizado
  useEffect(() => {
    sessionStorage.setItem('chatHistory', JSON.stringify(chatHistory));
  }, [chatHistory]);

  // Adicionar um efeito para ajustar o viewport em dispositivos móveis
  useEffect(() => {
    // Ajusta a meta tag viewport para garantir que o conteúdo se adapte à tela
    const viewport = document.querySelector('meta[name=viewport]');
    if (viewport) {
      viewport.content =
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
    }

    // Desabilita o scroll quando a vitrine estiver aberta
    if (showVitrine) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showVitrine]);

  // ---------------------------
  // MANIPULAÇÃO DE ESTADO
  // ---------------------------
  const resetPage = () => {
    setQuery('');
  };

  const handleInputChange = e => {
    setQuery(e.target.value);
  };

  // Pressionar Enter no input principal para abrir o chat
  const handleEnterKey = e => {
    if (e.key === 'Enter' && query.trim() !== '') {
      openChat();
    }
  };

  // ---------------------------
  // CHAT COM GPTMIX
  // ---------------------------
  // Abre o chat e envia a primeira mensagem
  const openChat = () => {
    if (query.trim() === '') return; // Não abre se estiver vazio
    setFadeOut(false);
    setShowChat(true);
    handleAskGPT(); // Envia imediatamente a pergunta digitada

    const chatbox = document.querySelector('.chatbox');
    if (chatbox) {
      chatbox.classList.add('fade-effect');
    }
  };

  // Fecha o chat
  const closeChat = () => {
    const chatbox = document.querySelector('.chatbox');
    if (chatbox) {
      chatbox.classList.add('fade-out');
    }
    setTimeout(() => {
      setShowChat(false);
      setFadeOut(false);
      setChatHistory([]);
      sessionStorage.removeItem('chatHistory');
      resetPage();
    }, 2000);
  };

  // Envia mensagem ao GPT
  const handleAskGPT = () => {
    if (query.trim() === '') return;

    const userMessage = query;
    const newHistory = [...chatHistory, { user: userMessage, response: '' }];
    setChatHistory(newHistory);
    sessionStorage.setItem('chatHistory', JSON.stringify(newHistory));

    setQuery('');
    setIsTyping(true);

    const messages = newHistory.map(message => ({
      role: 'user',
      content: message.user,
    }));

    fetch('https://mix-gpt-server.vercel.app/api/gpt', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ history: messages }),
    })
      .then(response => response.json())
      .then(data => {
        setIsTyping(false);
        if (data.message) {
          setChatHistory(prevHistory => {
            const updatedHistory = [...prevHistory];
            updatedHistory[updatedHistory.length - 1].response = data.message;
            sessionStorage.setItem('chatHistory', JSON.stringify(updatedHistory));
            return updatedHistory;
          });
        } else {
          console.error('Resposta inválida da API GPT:', data);
        }
      })
      .catch(error => {
        console.error('Erro ao consultar GPT:', error);
        setIsTyping(false);
      });
  };

  // Fecha o chat ao pressionar Enter dentro do chat
  useEffect(() => {
    const handleChatKeyDown = event => {
      if (showChat) {
        if (event.key === 'Escape') {
          closeChat();
        } else if (event.key === 'Enter') {
          handleAskGPT();
        }
      }
    };
    document.addEventListener('keydown', handleChatKeyDown);
    return () => document.removeEventListener('keydown', handleChatKeyDown);
  }, [showChat, query]);

  // Foca no input do chat ao abrir
  useEffect(() => {
    if (showChat && chatInputRef.current) {
      chatInputRef.current.focus();
    }
  }, [showChat]);

  // ---------------------------
  // LOGOUT
  // ---------------------------
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');
    setUsername('');
    setIsLoggedIn(false);
    setShowVitrine(false);
  };

  // ---------------------------
  // NAVEGAÇÃO PARA APLICATIVOS
  // ---------------------------
  const navigateToApp = appPath => {
    if (appPath === '/gptmix') {
      // Abre o chat do GPTMix
      setShowVitrine(false);
      setActiveApp(null);
      setShowRBIIframe(false);
      setTimeout(() => {
        setShowChat(true);
        if (chatInputRef.current) {
          chatInputRef.current.focus();
        }
      }, 100);
    } else if (appPath === '/mix-monitor') {
      // Abre o Mix Monitor
      setShowVitrine(false);
      setShowChat(false);
      setShowRBIIframe(false);
      setActiveApp('mix-monitor');
    } else if (appPath === '/agostime') {
      // Abre o Agostime
      setShowVitrine(false);
      setShowChat(false);
      setShowRBIIframe(false);
      setActiveApp('agostime');
    } else if (appPath === '/roadmap') {
      // Abre o Roadmap
      setShowVitrine(false);
      setShowChat(false);
      setShowRBIIframe(false);
      setActiveApp('roadmap');
    } else if (appPath === '/roadmap-admin') {
      // Abre o Roadmap Admin
      setShowVitrine(false);
      setShowChat(false);
      setShowRBIIframe(false);
      setActiveApp('roadmap-admin');
    } else if (appPath === '/rbi-site') {
      // Abre o iframe do site RBI
      setShowVitrine(false);
      setShowChat(false);
      setActiveApp(null);
      setShowRBIIframe(true);
    }
  };

  // Função para voltar à vitrine
  const backToVitrine = () => {
    setShowChat(false);
    setActiveApp(null);
    setShowRBIIframe(false);
    setShowVitrine(true);
  };

  // ---------------------------
  // RENDERIZAÇÃO
  // ---------------------------
  return (
    <div className="container fade-effect">
      {/* Botão de Login/Logoff */}
      {!isLoggedIn && !showVitrine && (
        <div className="login-text" onClick={toggleLogin}>
          Login
        </div>
      )}

      {/* Overlay para o formulário de login */}
      {showLogin && !isLoggedIn && !showVitrine && (
        <div className="login-overlay" onClick={() => setShowLogin(false)}></div>
      )}

      {/* Formulário de Login */}
      {showLogin && !isLoggedIn && !showVitrine && (
        <div className="login-form">
          <h3 style={{ marginTop: 0, marginBottom: '20px', color: '#4CAF50' }}>Login</h3>
          <input type="text" placeholder="Usuário" ref={userInputRef} onKeyDown={handleKeyDown} />
          <input
            type="password"
            placeholder="Senha"
            ref={passwordInputRef}
            onKeyDown={handleKeyDown}
          />
          <button ref={loginButtonRef} onClick={handleLogin}>
            Entrar
          </button>
        </div>
      )}

      {/* Logo - Apenas mostrar quando não estiver logado e não estiver mostrando a vitrine */}
      {!isLoggedIn && !showVitrine && (
        <img
          src="/img/mix.png"
          alt="Logo Rádio Mix"
          className="logo"
          onClick={resetPage}
          style={{ cursor: 'pointer' }}
        />
      )}

      {/* Campo de texto e botão para abrir o chat com GPTMix (só aparece se o chat estiver fechado e não estiver mostrando a vitrine) */}
      {!showChat && !showVitrine && !isLoggedIn && (
        <>
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            onKeyDown={handleEnterKey}
            placeholder="Pode perguntar! Sei tudo sobre música ;)"
            autoComplete="off"
            id="newTitle"
            style={{
              display: 'block',
              margin: '20px auto 10px auto',
              padding: '10px',
              width: '300px',
              fontSize: '1em',
              borderRadius: '8px',
              border: '1px solid #ccc',
              textAlign: 'center',
            }}
          />
          <button
            id="chatButton"
            onClick={openChat}
            className="fade-effect"
            style={{
              padding: '10px 20px',
              cursor: 'pointer',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              display: 'block',
              margin: '0 auto',
            }}
          >
            Pergunte ao GPTMix
          </button>
        </>
      )}

      {/* Vitrine de Aplicativos (após login) */}
      {isLoggedIn && showVitrine && (
        <div className="vitrine-container fade-in">
          {/* Logo reduzida no topo direito */}
          <div className="logo-container">
            <img src="/img/mix.png" alt="Logo Rádio Mix" className="logo-reduzida" />
          </div>

          {/* Botão de Logout */}
          <button onClick={handleLogout} className="logout-button">
            Sair
          </button>

          <h2 style={{ marginTop: '20px', marginBottom: '40px' }}>Escolha um aplicativo:</h2>

          <div className="apps-grid">
            <div className="app-card" onClick={() => navigateToApp('/gptmix')}>
              <img src="/img/gptmix-icon.svg" alt="GPTMix" className="app-icon" />
              <h3>GPTMix</h3>
              <p>Assistente virtual da Rádio Mix</p>
            </div>

            <div className="app-card" onClick={() => navigateToApp('/agostime')}>
              <img src="/img/agostime-icon.svg" alt="Agostime" className="app-icon" />
              <h3>Agostime</h3>
              <p>Sincronizador de Tempo Ultra Preciso</p>
            </div>

            <div className="app-card" onClick={() => navigateToApp('/mix-monitor')}>
              <img src="/img/monitor-icon.svg" alt="Mix Monitor" className="app-icon" />
              <h3>Mix Monitor</h3>
              <p>Monitoramento de Streams</p>
            </div>

            <div className="app-card" onClick={() => navigateToApp('/roadmap')}>
              <img src="/img/roadmap-icon.svg" alt="Roadmap TI" className="app-icon" />
              <h3>Roadmap TI</h3>
              <p>Planejamento Estratégico de TI</p>
            </div>

            {username === 'Fabio' && (
              <div className="app-card" onClick={() => navigateToApp('/roadmap-admin')}>
                <img src="/img/roadmap-icon.svg" alt="Admin Roadmap" className="app-icon" />
                <h3>Admin Roadmap</h3>
                <p>Gerenciamento do Roadmap TI</p>
              </div>
            )}

            <div className="app-card" onClick={() => navigateToApp('/rbi-site')}>
              <img src="/img/rbi-icon.svg" alt="Novo Site RBI" className="app-icon" />
              <h3>Novo Site RBI</h3>
              <p>Portal da RBI TV</p>
            </div>
          </div>

          {/* Rodapé com o player e texto */}
          <footer className="vitrine-footer">
            <div className="player-container">
              <audio controls style={{ width: '250px' }}>
                <source
                  src="https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SAOPAULO.mp3"
                  type="audio/mpeg"
                />
                Seu navegador não suporta o elemento de áudio.
              </audio>
            </div>
            <p>Desenvolvido por Fabio Carvalho</p>
          </footer>
        </div>
      )}

      {/* Chatbox para interação com o GPTMix (aparece se o chat estiver aberto) */}
      {showChat && (
        <div className={`chatbox fade-effect ${fadeOut ? 'fade-out' : ''}`}>
          <div className="chatbox-header">
            <button className="back-button" onClick={backToVitrine}>
              Voltar
            </button>
            <button className="close-chat" onClick={closeChat}>
              X
            </button>
          </div>
          <h2>Chat com GPTMix</h2>
          <div className="chat-history">
            {chatHistory.map((message, index) => (
              <div key={index} className="chat-message fade-effect">
                <p>
                  <strong>Você:</strong> {message.user}
                </p>
                <p>
                  <strong>GPTMix:</strong> {message.response}
                </p>
              </div>
            ))}
            {isTyping && (
              <div className="chat-message typing-animation">
                <p>
                  <strong>GPTMix:</strong> <span className="dots">•••</span>
                </p>
              </div>
            )}
          </div>
          <input
            ref={chatInputRef}
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Digite sua pergunta..."
          />
          <button onClick={handleAskGPT}>Enviar</button>
        </div>
      )}

      {/* Mix Monitor (aparece se o activeApp for 'mix-monitor') */}
      {activeApp === 'mix-monitor' && (
        <div className="app-container">
          <MixMonitor onBack={backToVitrine} />
        </div>
      )}

      {/* AgosTime (aparece se o activeApp for 'agostime') */}
      {activeApp === 'agostime' && (
        <div className="app-container">
          <AgosTime onBack={backToVitrine} />
        </div>
      )}

      {/* Roadmap (aparece se o activeApp for 'roadmap') */}
      {activeApp === 'roadmap' && (
        <div className="app-container">
          <Roadmap onBack={backToVitrine} />
        </div>
      )}

      {/* RoadmapAdmin (aparece se o activeApp for 'roadmap-admin') */}
      {activeApp === 'roadmap-admin' && (
        <div className="app-container">
          <RoadmapAdmin onBack={backToVitrine} />
        </div>
      )}

      {/* RBI Site Iframe */}
      {showRBIIframe && (
        <div className="app-container">
          <div className="iframe-header">
            <button className="back-button" onClick={backToVitrine}>
              Voltar
            </button>
            <h2>Novo Site RBI</h2>
          </div>
          <iframe
            src="https://darkgrey-salmon-518818.hostingersite.com/"
            title="Novo Site RBI"
            className="rbi-iframe"
          ></iframe>
        </div>
      )}

      {/* Player de áudio (apenas na tela inicial quando não logado e não mostrando a vitrine) */}
      {!isLoggedIn && !showVitrine && !showChat && (
        <div className="player-container" style={{ marginTop: '20px' }}>
          <audio controls>
            <source
              src="https://playerservices.streamtheworld.com/api/livestream-redirect/MIXFM_SAOPAULO.mp3"
              type="audio/mpeg"
            />
            Seu navegador não suporta o elemento de áudio.
          </audio>
        </div>
      )}

      {/* Rodapé (apenas se o chat estiver fechado, não estiver logado e não estiver mostrando a vitrine) */}
      {!showChat && !isLoggedIn && !showVitrine && (
        <footer className="footer fade-effect" style={{ marginTop: '30px' }}>
          <p>Desenvolvido por Fabio Carvalho</p>
        </footer>
      )}
    </div>
  );
}

export default App;
