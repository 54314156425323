import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../lib/supabase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { 
  FaCloud, 
  FaCheckCircle, 
  FaExclamationTriangle,
  FaSpinner,
  FaPlay,
  FaTimes,
  FaArrowLeft,
  FaPlus,
  FaSave,
  FaBan,
  FaEdit,
  FaTrashAlt,
  FaUserAlt,
  FaCode,
  FaInfoCircle,
  FaSync,
  FaFile,
  FaRegCalendarAlt,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';
import { FiSave, FiX, FiPlus, FiEdit2, FiTrash2, FiUpload, FiFile, FiCheckCircle, FiAlertCircle } from 'react-icons/fi';
import { BiLoaderAlt } from 'react-icons/bi';
import { IoTimeOutline, IoCheckmarkCircle, IoHourglassOutline } from 'react-icons/io5';
import '../styles/RoadmapAdmin.css';

// Componente para administração do Roadmap
const RoadmapAdmin = ({ onBack }) => {
  // Estado dos itens do Roadmap
  const [roadmapItems, setRoadmapItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  // Estado para o formulário de edição
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  
  // Configurações do editor Quill
  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image', 'video'],
      ['clean']
    ]
  };
  
  const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image', 'video'
  ];

  // Novas opções predefinidas para facilitar o preenchimento
  const statusOptions = [
    { value: 'Planejado', label: 'Planejado', icon: <IoHourglassOutline /> },
    { value: 'Em Progresso', label: 'Em Progresso', icon: <IoTimeOutline /> },
    { value: 'Concluído', label: 'Concluído', icon: <IoCheckmarkCircle /> }
  ];

  const periodoOptions = [
    { value: 'Q1 2024', label: 'Q1 2024 (Jan-Mar)' },
    { value: 'Q2 2024', label: 'Q2 2024 (Abr-Jun)' },
    { value: 'Q3 2024', label: 'Q3 2024 (Jul-Set)' },
    { value: 'Q4 2024', label: 'Q4 2024 (Out-Dez)' },
    { value: 'Q1 2025', label: 'Q1 2025 (Jan-Mar)' },
    { value: 'Q2 2025', label: 'Q2 2025 (Abr-Jun)' }
  ];

  const tecnologiasComuns = [
    'React', 'Angular', 'Vue.js', 'Node.js', 'Express', 
    'Python', 'Django', 'Flask', 'Java', 'Spring Boot',
    'AWS', 'Azure', 'GCP', 'Docker', 'Kubernetes',
    'SQL Server', 'MongoDB', 'PostgreSQL', 'Firebase',
    'Machine Learning', 'Blockchain', 'IoT', 'BI'
  ];

  // Referências para uploads de arquivos
  const imageInputRef = useRef(null);
  const documentInputRef = useRef(null);

  // Carregar os dados do Supabase
  useEffect(() => {
    fetchRoadmapItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Efeito para garantir que o editor Quill seja inicializado corretamente
  useEffect(() => {
    if (isEditing) {
      // Garantir que os elementos do Quill estejam visíveis
      setTimeout(() => {
        const editor = document.querySelector('.ql-editor');
        if (editor) {
          editor.style.minHeight = '200px';
        }
      }, 100);
    }
  }, [isEditing]);

  // Função para buscar os itens do Roadmap do Supabase
  const fetchRoadmapItems = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);
    
    try {
      // Buscar dados da tabela roadmap no Supabase
      const { data, error: supabaseError } = await supabase
        .from('roadmap')
        .select('*')
        .order('ordem', { ascending: true }) // Ordenar pelo campo ordem primeiro
        .order('id', { ascending: true }); // Usar ID como critério secundário

      if (supabaseError) {
        throw new Error(supabaseError.message);
      }
      
      if (data && data.length > 0) {
        // Processar os dados recebidos
        const processedData = data.map(item => ({
          ...item,
          // Converter strings JSON de volta para arrays
          responsaveis_funcoes: item.responsaveis_funcoes ? JSON.parse(item.responsaveis_funcoes) : [],
          responsaveis_convidados: item.responsaveis_convidados ? JSON.parse(item.responsaveis_convidados) : [],
          // Manter o processamento existente para outros campos
          technology: item.technology ? item.technology.split(',') : []
        }));

        setRoadmapItems(processedData);
        // Armazenar no localStorage como backup
        localStorage.setItem('roadmapItems', JSON.stringify(processedData));
      } else {
        // Se não houver dados, tentar usar os dados do JSON local como fallback
        await fetchLocalFallback();
      }
    } catch (error) {
      console.error('Erro ao buscar dados do Supabase:', error);
      // Tentar carregar do JSON local ou localStorage como fallback
      await fetchLocalFallback();
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar dados do JSON local como fallback
  const fetchLocalFallback = async () => {
    try {
      const response = await fetch('/data/roadmap.json');
      if (!response.ok) {
        throw new Error(`Erro ao carregar dados: ${response.status}`);
      }
      const data = await response.json();
      
      if (data && data.items) {
        setRoadmapItems(data.items);
        setMessage({ 
          text: 'Usando dados do armazenamento local, pois o banco de dados está indisponível', 
          type: 'warning' 
        });
        // Armazenar no localStorage como backup
        localStorage.setItem('roadmapItems', JSON.stringify(data.items));
      } else {
        // Se o JSON local não funcionar, tentar o localStorage
        const localData = localStorage.getItem('roadmapItems');
        if (localData) {
          setRoadmapItems(JSON.parse(localData));
          setMessage({ 
            text: 'Usando dados em cache. Não foi possível acessar o banco de dados.', 
            type: 'warning' 
          });
        } else {
          setError('Não foi possível carregar os dados. Tente novamente mais tarde.');
        }
      }
    } catch (localError) {
      console.error('Erro ao carregar dados do JSON local:', localError);
      // Última tentativa: localStorage
      try {
        const localData = localStorage.getItem('roadmapItems');
        if (localData) {
          setRoadmapItems(JSON.parse(localData));
          setMessage({ 
            text: 'Usando dados em cache. Não foi possível acessar o banco de dados.', 
            type: 'warning' 
          });
        } else {
          setError('Não foi possível carregar os dados. Tente novamente mais tarde.');
        }
      } catch (storageError) {
        console.error('Erro ao carregar dados do localStorage:', storageError);
        setError('Não foi possível carregar os dados. Tente novamente mais tarde.');
      }
    }
  };

  // Mapeia string do ícone para o componente real
  const getIconComponent = (iconName) => {
    switch(iconName) {
      case 'check': return <FaCheckCircle />;
      case 'clock': return <FaSpinner className="spinning" />;
      case 'calendar': return <FaRegCalendarAlt />;
      case 'warning': return <FaExclamationTriangle />;
      case 'times': return <FaTimes />;
      case 'play': return <FaPlay />;
      case 'info': return <FaInfoCircle />;
      default: return <FaCloud />;
    }
  };

  // Cores para cada status de projeto
  const getStatusColor = (status) => {
    switch(status) {
      case 'concluído': return '#4CAF50';
      case 'em andamento': return '#2196F3';
      case 'planejado': return '#9C27B0';
      case 'atrasado': return '#FF9800';
      case 'cancelado': return '#F44336';
      case 'iniciando': return '#00BCD4';
      default: return '#607D8B';
    }
  };

  // Função para editar um item existente
  const editItem = (item) => {
    console.log('Editando item:', item);
    
    // Copia profunda do item para evitar referências diretas
    const itemCopy = JSON.parse(JSON.stringify(item));
    
    // Formatar valores monetários para exibição
    if (itemCopy.custoTotal && !itemCopy.custoTotal.includes('R$')) {
      // Se não tiver o formato R$, converter para o formato de exibição
      const valor = parseFloat(itemCopy.custoTotal);
      if (!isNaN(valor)) {
        itemCopy.custoTotal = `R$ ${valor.toFixed(2).replace('.', ',')}`;
      }
    }
    
    if (itemCopy.economiaEsperada && !itemCopy.economiaEsperada.includes('R$')) {
      // Se não tiver o formato R$, converter para o formato de exibição
      const valor = parseFloat(itemCopy.economiaEsperada);
      if (!isNaN(valor)) {
        itemCopy.economiaEsperada = `R$ ${valor.toFixed(2).replace('.', ',')}`;
      }
    }
    
    // Garantir que technology seja um array
    if (typeof itemCopy.technology === 'string' && itemCopy.technology) {
      itemCopy.technology = itemCopy.technology.split(',').map(tech => tech.trim());
    } else if (!Array.isArray(itemCopy.technology)) {
      itemCopy.technology = [];
    }
    
    // Garantir que documentos seja um array
    if (!Array.isArray(itemCopy.documentos)) {
      itemCopy.documentos = [];
    }
    
    setCurrentItem(itemCopy);
    setIsEditing(true);
  };

  // Função para adicionar um novo item
  const addNewItem = () => {
    console.log('Adicionando novo item');
    
    // Determinar a próxima ordem (maior ordem atual + 1)
    let nextOrder = 0;
    if (roadmapItems.length > 0) {
      const maxOrder = Math.max(...roadmapItems.map(item => item.ordem || 0));
      nextOrder = maxOrder + 1;
    }
    
    const newItem = {
      title: '',
      cardTitle: 'Q1 2024',
      cardSubtitle: 'Jan - Mar',
      cardDetailedText: '',
      description: '',
      status: 'planejado',
      icon: 'info',
      iconColor: '#9e9e9e',
      responsaveis: '',
      responsaveis_funcoes: [],
      responsaveis_convidados: [],
      technology: [],
      custoTotal: '',
      economiaEsperada: '',
      documentos: [],
      imagemProjeto: '',
      ordem: nextOrder
    };
    
    console.log('Novo item criado:', newItem);
    setCurrentItem(newItem);
    setIsEditing(true);
  };

  // Função para formatar valores em reais
  const formatarReais = (valor) => {
    let v = valor.replace(/\D/g, '');
    v = (parseInt(v) / 100).toFixed(2).replace('.', ',');
    return v ? `R$ ${v}` : '';
  };

  // Função para converter formato em reais para número
  const converterReaisParaNumero = (valor) => {
    return valor ? parseFloat(valor.replace(/[^\d,]/g, '').replace(',', '.')) : 0;
  };

  // Tratar input de valores monetários
  const handleCustoChange = (e, field) => {
    let valor = e.target.value;
    
    // Se o usuário está apagando o valor completamente
    if (!valor || valor === 'R$ ' || valor === 'R$') {
      updateField(field, '');
      return;
    }
    
    // Remover toda formatação existente (R$, espaços, vírgulas, pontos)
    valor = valor.replace(/[^\d]/g, '');
    
    // Converter para o formato centavos
    let valorNumerico = parseInt(valor);
    
    // Se não for um número válido, retornar
    if (isNaN(valorNumerico)) {
      updateField(field, '');
      return;
    }
    
    // Formatar como R$ X,XX (dividindo por 100 para ter as casas decimais)
    let valorFormatado = `R$ ${(valorNumerico / 100).toFixed(2).replace('.', ',')}`;
    
    // Atualizar o campo com o valor formatado
    updateField(field, valorFormatado);
  };

  // Atualizar período com trimestre e ano
  const handleTrimestreChange = (trimestre) => {
    // Atualizar o período
    const currentYear = new Date().getFullYear();
    const periodoText = `${trimestre} ${currentYear}`;
    updateField('cardTitle', periodoText);
    
    // Atualizar automaticamente o detalhe do período
    let detalheText = '';
    switch(trimestre) {
      case 'Q1':
        detalheText = 'Jan - Mar';
        break;
      case 'Q2':
        detalheText = 'Abr - Jun';
        break;
      case 'Q3':
        detalheText = 'Jul - Set';
        break;
      case 'Q4':
        detalheText = 'Out - Dez';
        break;
      default:
        detalheText = '';
    }
    
    updateField('cardSubtitle', detalheText);
  };
  
  // Atualizar o ano do período
  const handleAnoChange = (e) => {
    const ano = e.target.value;
    const trimestre = currentItem.cardTitle ? currentItem.cardTitle.split(' ')[0] : 'Q1';
    updateField('cardTitle', `${trimestre} ${ano}`);
  };

  // Preparar o item para salvar no banco
  const uploadImageToSupabase = async (file) => {
    if (!file) return null;
    
    try {
      // Criar um nome único para o arquivo usando timestamp
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
      const filePath = `roadmap-images/${fileName}`;
      
      // Upload do arquivo para o Supabase
      const { data, error } = await supabase.storage
        .from('mixgpt-images')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false
        });
        
      if (error) {
        console.error('Erro ao fazer upload da imagem:', error);
        return null;
      }
      
      // Obter URL pública da imagem
      const { data: urlData } = supabase.storage
        .from('mixgpt-images')
        .getPublicUrl(filePath);
        
      return urlData.publicUrl;
    } catch (error) {
      console.error('Erro ao processar upload da imagem:', error);
      return null;
    }
  };

  // Função para salvar as alterações do item no Supabase
  const saveItem = async () => {
    // Validar se todos os campos obrigatórios estão preenchidos
    if (!currentItem.title || !currentItem.cardTitle || !currentItem.status) {
      setMessage({ text: 'Por favor, preencha todos os campos obrigatórios.', type: 'error' });
      return;
    }

    // Garantir que documentos seja um array
    const documentosArray = Array.isArray(currentItem.documentos) ? currentItem.documentos : [];

    // Tratar os valores monetários para armazenamento
    let custoTotal = currentItem.custoTotal || '';
    let economiaEsperada = currentItem.economiaEsperada || '';
    
    // Converter de formato exibido (R$ X,XX) para valor decimal no banco de dados
    if (custoTotal && custoTotal.includes('R$')) {
      custoTotal = converterReaisParaNumero(custoTotal).toString();
    }
    
    if (economiaEsperada && economiaEsperada.includes('R$')) {
      economiaEsperada = converterReaisParaNumero(economiaEsperada).toString();
    }

    // Preparar o item para salvar no banco
    const itemToSave = {
      title: currentItem.title,
      cardTitle: currentItem.cardTitle || '',
      cardSubtitle: currentItem.cardSubtitle || '',
      cardDetailedText: currentItem.cardDetailedText || '',
      status: currentItem.status,
      icon: currentItem.icon || 'info',
      iconColor: currentItem.iconColor || '#9e9e9e',
      description: currentItem.description || '',
      // Garantir que responsáveis seja uma string
      responsaveis: typeof currentItem.responsaveis === 'string' 
        ? currentItem.responsaveis 
        : Array.isArray(currentItem.responsaveis) 
          ? currentItem.responsaveis.join(', ') 
          : '',
      // Converter arrays para JSON antes de salvar
      responsaveis_funcoes: JSON.stringify(currentItem.responsaveis_funcoes || []),
      responsaveis_convidados: JSON.stringify(currentItem.responsaveis_convidados || []),
      // Garantir que technology seja uma string
      technology: Array.isArray(currentItem.technology) 
        ? currentItem.technology.join(',') 
        : currentItem.technology || '',
      // Campos adicionais - com valores monetários tratados
      custoTotal: custoTotal,
      economiaEsperada: economiaEsperada, 
      documentos: documentosArray,
      imagemProjeto: currentItem.imagemProjeto || '',
      // Campo de ordem para controlar a sequência dos projetos
      ordem: currentItem.ordem || 0
    };

    console.log('Objeto que será salvo:', itemToSave);

    try {
      setLoading(true);
      
      if (currentItem.id) {
        // Atualizar item existente
        console.log('Atualizando item com ID:', currentItem.id);
        const { data, error: updateError } = await supabase
          .from('roadmap')
          .update(itemToSave)
          .eq('id', currentItem.id)
          .select();
        
        if (updateError) {
          console.error('Erro detalhado ao atualizar:', updateError);
          throw new Error(updateError.message);
        }
        
        console.log('Item atualizado com sucesso:', data);
      } else {
        // Para novos itens, primeiro buscar o maior ID existente
        console.log('Criando novo item');
        const { data: maxIdData, error: maxIdError } = await supabase
          .from('roadmap')
          .select('id')
          .order('id', { ascending: false })
          .limit(1);
          
        if (maxIdError) {
          console.error('Erro ao buscar maior ID:', maxIdError);
          throw new Error(maxIdError.message);
        }
        
        // Calcular próximo ID
        const nextId = maxIdData && maxIdData.length > 0 ? maxIdData[0].id + 1 : 1;
        console.log('Próximo ID será:', nextId);
        
        // Adicionar novo item com ID explícito
        const { data: insertData, error: insertError } = await supabase
          .from('roadmap')
          .insert({
            ...itemToSave,
            id: nextId,
            created_at: new Date().toISOString()
          })
          .select();
        
        if (insertError) {
          console.error('Erro detalhado ao inserir:', insertError);
          throw new Error(insertError.message);
        }
        
        console.log('Novo item criado com sucesso:', insertData);
      }
      
      // Atualizar a lista de itens
      await fetchRoadmapItems();
      
      // Resetar formulário
      setIsEditing(false);
      setCurrentItem(null);
      
      // Exibir mensagem de sucesso
      setMessage({ text: 'Projeto salvo com sucesso!', type: 'success' });
    } catch (error) {
      console.error('Erro ao salvar no Supabase:', error);
      
      // Salvar no localStorage como fallback
      try {
        let updatedItems;
        
        if (currentItem.id) {
          // Atualizar item existente
          updatedItems = roadmapItems.map(item => 
            item.id === currentItem.id ? { ...currentItem } : item
          );
        } else {
          // Adicionar novo item
          const newId = roadmapItems.length > 0 
            ? Math.max(...roadmapItems.map(item => item.id)) + 1 
            : 1;
            
          updatedItems = [...roadmapItems, { ...currentItem, id: newId }];
        }

        // Atualizar o estado e o localStorage
        setRoadmapItems(updatedItems);
        localStorage.setItem('roadmapItems', JSON.stringify(updatedItems));
        
        // Resetar formulário
        setIsEditing(false);
        setCurrentItem(null);
        
        // Exibir mensagem de aviso
        setMessage({ 
          text: 'Projeto salvo localmente. Não foi possível acessar o banco de dados.', 
          type: 'warning' 
        });
      } catch (localError) {
        console.error('Erro ao salvar localmente:', localError);
        setMessage({ 
          text: 'Erro ao salvar o projeto. Por favor, tente novamente.', 
          type: 'error' 
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // Cancelar edição
  const cancelEdit = () => {
    setCurrentItem(null);
    setIsEditing(false);
  };

  // Função para remover um item do Supabase
  const removeItem = async (id) => {
    if (window.confirm('Tem certeza que deseja remover este projeto?')) {
      try {
        setLoading(true);
        
        // Remover do Supabase
        const { error: deleteError } = await supabase
          .from('roadmap')
          .delete()
          .eq('id', id);
        
        if (deleteError) throw new Error(deleteError.message);
        
        // Atualizar a lista de itens
        await fetchRoadmapItems();
        
        // Exibir mensagem de sucesso
        setMessage({ text: 'Projeto removido com sucesso!', type: 'success' });
      } catch (error) {
        console.error('Erro ao remover do Supabase:', error);
        
        // Remover localmente como fallback
        try {
          const updatedItems = roadmapItems.filter(item => item.id !== id);
          setRoadmapItems(updatedItems);
          localStorage.setItem('roadmapItems', JSON.stringify(updatedItems));
          
          setMessage({ 
            text: 'Projeto removido localmente. Não foi possível acessar o banco de dados.', 
            type: 'warning' 
          });
        } catch (localError) {
          console.error('Erro ao remover localmente:', localError);
          setMessage({ 
            text: 'Erro ao remover o projeto. Por favor, tente novamente.', 
            type: 'error' 
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  // Atualizar um campo do projeto atual
  const updateField = (field, value) => {
    console.log('Atualizando campo:', field, 'com valor:', value);
    setCurrentItem(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Atualizar um array (responsáveis ou tecnologias)
  const updateArray = (field, value) => {
    // Divide a string por vírgulas e remove espaços
    const arrayValue = value.split(',').map(item => item.trim());
    setCurrentItem(prev => ({
      ...prev,
      [field]: arrayValue
    }));
  };

  // Função para obter array de responsáveis
  const getResponsaveis = (responsaveis) => {
    if (!responsaveis) return [''];
    
    if (typeof responsaveis === 'string') {
      const array = responsaveis.split(',').map(r => r.trim()).filter(r => r !== '');
      return array.length === 0 ? [''] : array;
    } else if (Array.isArray(responsaveis)) {
      return responsaveis.filter(Boolean).length === 0 ? [''] : responsaveis.filter(Boolean);
    }
    
    return [''];
  };

  // Renderizar as tecnologias
  const renderTechnologies = (technology) => {
    if (!technology) return null;
    
    let techArray = technology;
    
    // Se for string, tentar parseá-la como JSON
    if (typeof technology === 'string') {
      try {
        techArray = JSON.parse(technology);
      } catch (error) {
        // Se não for JSON válido, dividir por vírgulas
        techArray = technology.split(',').map(item => item.trim());
      }
    }
    
    // Garantir que é um array
    if (!Array.isArray(techArray)) {
      return null;
    }
    
    // Renderizar cada tecnologia
    return techArray.map((tech, index) => (
      tech && <span key={index} className="tech-tag">{tech}</span>
    ));
  };

  // Upload de arquivo para o Supabase Storage
  const handleFileUpload = async (e, tipo) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      // Usamos apenas o timestamp e nome do arquivo, sem subdiretórios
      const fileName = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
      
      console.log('Iniciando upload de arquivo:', fileName, 'tipo:', tipo);
      
      // Upload do arquivo para o Supabase Storage com configurações explícitas
      const { data, error } = await supabase.storage
        .from('roadmap-files')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: true, // Sobrescrever se existir
          contentType: file.type // Definir o tipo de conteúdo explicitamente
        });
      
      if (error) {
        console.error('Erro detalhado de upload:', error);
        throw new Error(error.message);
      }
      
      console.log('Upload bem-sucedido:', data);
      
      // Obter URL pública do arquivo
      const { data: urlData } = supabase.storage
        .from('roadmap-files')
        .getPublicUrl(fileName);
      
      const fileUrl = urlData.publicUrl;
      console.log('URL pública gerada:', fileUrl);
      
      if (tipo === 'documento') {
        // Adicionar documento à lista, garantindo que documentos seja tratado como array
        setCurrentItem(prev => {
          // Certificar que documentos é um array
          const documentosAtuais = Array.isArray(prev.documentos) ? prev.documentos : [];
          const novoDocumentos = [...documentosAtuais, { nome: file.name, url: fileUrl }];
          console.log('Lista de documentos atualizada:', novoDocumentos);
          return {
            ...prev,
            documentos: novoDocumentos
          };
        });
      } else if (tipo === 'imagem') {
        // Atualizar imagem do projeto
        console.log('Atualizando imagemProjeto com URL:', fileUrl);
        setCurrentItem(prev => {
          const atualizado = {
            ...prev,
            imagemProjeto: fileUrl
          };
          console.log('Item atualizado com imagem:', atualizado);
          return atualizado;
        });
      }
      
      setMessage({ text: `Arquivo ${tipo} enviado com sucesso!`, type: 'success' });
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
      setMessage({ text: `Erro ao enviar arquivo: ${error.message}`, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Remover documento da lista
  const removeDocumento = (index) => {
    setCurrentItem(prev => {
      // Garantir que documentos é um array
      const documentos = Array.isArray(prev.documentos) ? prev.documentos : [];
      return {
        ...prev,
        documentos: documentos.filter((_, i) => i !== index)
      };
    });
  };

  // Função para adicionar um novo responsável
  const addResponsavel = () => {
    console.log('Adicionando novo responsável');
    setCurrentItem(prev => {
      if (!prev) return prev;

      // Garantir que os arrays existam
      const responsaveis = prev.responsaveis ? prev.responsaveis.split(',').filter(Boolean) : [];
      const funcoes = Array.isArray(prev.responsaveis_funcoes) ? [...prev.responsaveis_funcoes] : [];
      const convidados = Array.isArray(prev.responsaveis_convidados) ? [...prev.responsaveis_convidados] : [];
      
      // Adicionar novo responsável vazio
      responsaveis.push('');
      funcoes.push('');
      convidados.push(false);
      
      return {
        ...prev,
        responsaveis: responsaveis.join(','),
        responsaveis_funcoes: funcoes,
        responsaveis_convidados: convidados
      };
    });
  };

  // Função para remover um responsável
  const removeResponsavel = (index) => {
    console.log('Removendo responsável no índice:', index);
    setCurrentItem(prev => {
      if (!prev) return prev;

      const responsaveis = getResponsaveis(prev.responsaveis);
      const funcoes = Array.isArray(prev.responsaveis_funcoes) ? [...prev.responsaveis_funcoes] : [];
      const convidados = Array.isArray(prev.responsaveis_convidados) ? [...prev.responsaveis_convidados] : [];
      
      responsaveis.splice(index, 1);
      funcoes.splice(index, 1);
      convidados.splice(index, 1);
      
      // Se removeu todos os responsáveis, mantenha um campo vazio
      if (responsaveis.length === 0) {
        responsaveis.push('');
        funcoes.push('');
        convidados.push(false);
      }
      
      return {
        ...prev,
        responsaveis: responsaveis.filter(r => r !== '').join(','),
        responsaveis_funcoes: funcoes,
        responsaveis_convidados: convidados
      };
    });
  };

  // Função para atualizar um responsável específico
  const updateResponsavel = (index, value) => {
    console.log('Atualizando responsável:', index, value);
    setCurrentItem(prev => {
      if (!prev) return prev;

      const responsaveis = getResponsaveis(prev.responsaveis);
      responsaveis[index] = value;
      
      return {
        ...prev,
        responsaveis: responsaveis.filter(r => r !== '').join(',')
      };
    });
  };

  // Função para atualizar a função de um responsável
  const updateResponsavelFuncao = (index, value) => {
    console.log('Atualizando função do responsável:', index, value);
    setCurrentItem(prev => {
      if (!prev) return prev;

      const funcoes = Array.isArray(prev.responsaveis_funcoes) ? [...prev.responsaveis_funcoes] : [];
      funcoes[index] = value;
      
      return {
        ...prev,
        responsaveis_funcoes: funcoes
      };
    });
  };

  // Função para atualizar o status de convidado de um responsável
  const updateResponsavelConvidado = (index, value) => {
    console.log('Atualizando status de convidado:', index, value);
    setCurrentItem(prev => {
      if (!prev) return prev;

      const convidados = Array.isArray(prev.responsaveis_convidados) ? [...prev.responsaveis_convidados] : [];
      convidados[index] = value;
      
      return {
        ...prev,
        responsaveis_convidados: convidados
      };
    });
  };

  // Função para mover um responsável para cima na lista
  const moverResponsavelParaCima = (index) => {
    if (index === 0) return; // Não pode mover para cima se já estiver no topo
    
    // Criar cópias dos arrays para manipular
    const nomes = Array.isArray(currentItem.responsaveis) 
      ? [...currentItem.responsaveis] 
      : currentItem.responsaveis.split(',').map(nome => nome.trim()).filter(Boolean);
    
    const funcoes = Array.isArray(currentItem.responsaveis_funcoes) 
      ? [...currentItem.responsaveis_funcoes] 
      : [];
    
    const convidados = Array.isArray(currentItem.responsaveis_convidados) 
      ? [...currentItem.responsaveis_convidados] 
      : [];
    
    // Trocar posições
    [nomes[index], nomes[index-1]] = [nomes[index-1], nomes[index]];
    
    if (funcoes.length > 0) {
      [funcoes[index], funcoes[index-1]] = [funcoes[index-1], funcoes[index]];
    }
    
    if (convidados.length > 0) {
      [convidados[index], convidados[index-1]] = [convidados[index-1], convidados[index]];
    }
    
    // Atualizar o estado
    setCurrentItem({
      ...currentItem,
      responsaveis: Array.isArray(currentItem.responsaveis) ? nomes : nomes.join(', '),
      responsaveis_funcoes: funcoes,
      responsaveis_convidados: convidados
    });
  };

  // Função para mover um responsável para baixo na lista
  const moverResponsavelParaBaixo = (index) => {
    const nomes = Array.isArray(currentItem.responsaveis) 
      ? [...currentItem.responsaveis] 
      : currentItem.responsaveis.split(',').map(nome => nome.trim()).filter(Boolean);
    
    if (index === nomes.length - 1) return; // Não pode mover para baixo se já estiver no final
    
    const funcoes = Array.isArray(currentItem.responsaveis_funcoes) 
      ? [...currentItem.responsaveis_funcoes] 
      : [];
    
    const convidados = Array.isArray(currentItem.responsaveis_convidados) 
      ? [...currentItem.responsaveis_convidados] 
      : [];
    
    // Trocar posições
    [nomes[index], nomes[index+1]] = [nomes[index+1], nomes[index]];
    
    if (funcoes.length > 0) {
      [funcoes[index], funcoes[index+1]] = [funcoes[index+1], funcoes[index]];
    }
    
    if (convidados.length > 0) {
      [convidados[index], convidados[index+1]] = [convidados[index+1], convidados[index]];
    }
    
    // Atualizar o estado
    setCurrentItem({
      ...currentItem,
      responsaveis: Array.isArray(currentItem.responsaveis) ? nomes : nomes.join(', '),
      responsaveis_funcoes: funcoes,
      responsaveis_convidados: convidados
    });
  };

  // Função para mover um projeto para cima na lista
  const moverProjetoParaCima = async (item) => {
    const index = roadmapItems.findIndex(i => i.id === item.id);
    if (index <= 0) return; // Já está no topo
    
    const projetoAnterior = roadmapItems[index - 1];
    
    try {
      setLoading(true);
      
      // Trocar as ordens dos dois projetos
      const ordemAtual = item.ordem || 0;
      const ordemAnterior = projetoAnterior.ordem || 0;
      
      // Atualizar o projeto atual para ter a ordem do anterior
      await supabase
        .from('roadmap')
        .update({ ordem: ordemAnterior })
        .eq('id', item.id);
        
      // Atualizar o projeto anterior para ter a ordem do atual
      await supabase
        .from('roadmap')
        .update({ ordem: ordemAtual })
        .eq('id', projetoAnterior.id);
      
      // Recarregar os dados
      await fetchRoadmapItems();
      
      setMessage({ text: 'Ordem atualizada com sucesso!', type: 'success' });
    } catch (error) {
      console.error('Erro ao atualizar ordem:', error);
      
      // Fallback para atualização local apenas
      const updatedItems = [...roadmapItems];
      updatedItems[index] = projetoAnterior;
      updatedItems[index - 1] = item;
      setRoadmapItems(updatedItems);
      localStorage.setItem('roadmapItems', JSON.stringify(updatedItems));
      
      setMessage({ text: 'Ordem atualizada localmente. Não foi possível acessar o banco de dados.', type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  // Função para mover um projeto para baixo na lista
  const moverProjetoParaBaixo = async (item) => {
    const index = roadmapItems.findIndex(i => i.id === item.id);
    if (index >= roadmapItems.length - 1) return; // Já está no final
    
    const proximoProjeto = roadmapItems[index + 1];
    
    try {
      setLoading(true);
      
      // Trocar as ordens dos dois projetos
      const ordemAtual = item.ordem || 0;
      const ordemProxima = proximoProjeto.ordem || 0;
      
      // Atualizar o projeto atual para ter a ordem do próximo
      await supabase
        .from('roadmap')
        .update({ ordem: ordemProxima })
        .eq('id', item.id);
        
      // Atualizar o próximo projeto para ter a ordem do atual
      await supabase
        .from('roadmap')
        .update({ ordem: ordemAtual })
        .eq('id', proximoProjeto.id);
      
      // Recarregar os dados
      await fetchRoadmapItems();
      
      setMessage({ text: 'Ordem atualizada com sucesso!', type: 'success' });
    } catch (error) {
      console.error('Erro ao atualizar ordem:', error);
      
      // Fallback para atualização local apenas
      const updatedItems = [...roadmapItems];
      updatedItems[index] = proximoProjeto;
      updatedItems[index + 1] = item;
      setRoadmapItems(updatedItems);
      localStorage.setItem('roadmapItems', JSON.stringify(updatedItems));
      
      setMessage({ text: 'Ordem atualizada localmente. Não foi possível acessar o banco de dados.', type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  // Renderizar o formulário de edição
  const renderEditForm = () => {
    if (!currentItem) return null;
    
    // Extrair o trimestre e ano do período atual
    let trimestre = 'Q1';
    let ano = new Date().getFullYear().toString();
    
    if (currentItem.cardTitle) {
      const parts = currentItem.cardTitle.split(' ');
      if (parts.length >= 1) {
        trimestre = parts[0];
      }
      if (parts.length >= 2) {
        ano = parts[1];
      }
    }
    
    return (
      <div className="edit-form">
        <h3>{currentItem.id ? 'Editar Projeto' : 'Novo Projeto'}</h3>
        
        <div className="form-group">
          <label>Título</label>
          <input 
            type="text" 
            value={currentItem.title || ''} 
            onChange={(e) => updateField('title', e.target.value)}
            placeholder="Título do projeto"
          />
        </div>
        
        <div className="form-row">
          <div className="form-group">
            <label>Trimestre</label>
            <select 
              value={trimestre} 
              onChange={(e) => handleTrimestreChange(e.target.value)}
            >
              <option value="Q1">Q1 (Jan-Mar)</option>
              <option value="Q2">Q2 (Abr-Jun)</option>
              <option value="Q3">Q3 (Jul-Set)</option>
              <option value="Q4">Q4 (Out-Dez)</option>
            </select>
          </div>
          
          <div className="form-group">
            <label>Ano</label>
            <select 
              value={ano} 
              onChange={handleAnoChange}
            >
              {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() + i - 2).map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          
          <div className="form-group">
            <label>Detalhe do Período</label>
            <input 
              type="text" 
              value={currentItem.cardSubtitle || ''} 
              readOnly
              className="readonly-input"
            />
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-group">
            <label>Status</label>
            <select 
              value={currentItem.status || 'planejado'} 
              onChange={(e) => updateField('status', e.target.value)}
            >
              <option value="planejado">Planejado</option>
              <option value="em andamento">Em Andamento</option>
              <option value="concluído">Concluído</option>
              <option value="atrasado">Atrasado</option>
              <option value="cancelado">Cancelado</option>
            </select>
          </div>
          
          <div className="form-group">
            <label>Ícone</label>
            <select 
              value={currentItem.icon || 'info'} 
              onChange={(e) => updateField('icon', e.target.value)}
            >
              <option value="info">Informação</option>
              <option value="check">Concluído</option>
              <option value="clock">Em Andamento</option>
              <option value="calendar">Planejado</option>
              <option value="warning">Alerta</option>
              <option value="times">Cancelado</option>
              <option value="play">Iniciando</option>
            </select>
          </div>
          
          <div className="form-group">
            <label>Cor do Ícone</label>
            <input 
              type="color" 
              value={currentItem.iconColor || '#9e9e9e'} 
              onChange={(e) => updateField('iconColor', e.target.value)}
            />
          </div>
        </div>
        
        <div className="form-group">
          <label>Ordem de Exibição</label>
          <input 
            type="number" 
            value={currentItem.ordem || 0} 
            onChange={(e) => updateField('ordem', parseInt(e.target.value, 10) || 0)}
            min="0"
            step="1"
          />
          <span className="form-tip">Projetos são ordenados do menor para o maior número</span>
        </div>
        
        <div className="form-group">
          <label>Descrição Breve</label>
          <input 
            type="text" 
            value={currentItem.cardDetailedText || ''} 
            onChange={(e) => updateField('cardDetailedText', e.target.value)}
            placeholder="Breve descrição para o card"
          />
        </div>
        
        <div className="form-group">
          <label>Descrição Detalhada</label>
          <div className="quill-editor-container">
            <ReactQuill 
              value={currentItem.description || ''}
              onChange={(content) => updateField('description', content)}
              modules={quillModules}
              formats={quillFormats}
              placeholder="Descrição detalhada do projeto"
              theme="snow"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Custo Total</label>
            <input 
              type="text" 
              value={currentItem.custoTotal || ''} 
              onChange={(e) => handleCustoChange(e, 'custoTotal')}
              placeholder="R$ 0,00"
            />
          </div>
          
          <div className="form-group">
            <label>Economia Esperada</label>
            <input 
              type="text" 
              value={currentItem.economiaEsperada || ''} 
              onChange={(e) => handleCustoChange(e, 'economiaEsperada')}
              placeholder="R$ 0,00"
            />
          </div>
        </div>
        
        <div className="form-group">
          <label>Responsáveis</label>
          <div className="responsaveis-edit">
            <div className="responsaveis-header">
              <span>Nome</span>
              <span>Função</span>
              <span>Status</span>
              <span>Ações</span>
            </div>
            
            {(() => {
              // Obter arrays de responsáveis
              const responsaveis = currentItem.responsaveis ? currentItem.responsaveis.split(',').filter(Boolean) : [''];
              const funcoes = Array.isArray(currentItem.responsaveis_funcoes) ? currentItem.responsaveis_funcoes : [''];
              const convidados = Array.isArray(currentItem.responsaveis_convidados) ? currentItem.responsaveis_convidados : [false];
              
              // Garantir que todos os arrays tenham o mesmo tamanho
              const maxLength = Math.max(responsaveis.length, funcoes.length, convidados.length);
              while (responsaveis.length < maxLength) responsaveis.push('');
              while (funcoes.length < maxLength) funcoes.push('');
              while (convidados.length < maxLength) convidados.push(false);
              
              return responsaveis.map((nome, index) => (
                <div 
                  key={`responsavel-${index}`} 
                  className={`responsavel-edit-row ${convidados[index] ? 'convidado' : ''}`}
                >
                  <input 
                    type="text" 
                    value={nome} 
                    onChange={(e) => updateResponsavel(index, e.target.value)}
                    placeholder="Nome do responsável"
                  />
                  <input 
                    type="text" 
                    value={funcoes[index] || ''} 
                    onChange={(e) => updateResponsavelFuncao(index, e.target.value)}
                    placeholder="Função/Cargo"
                  />
                  <div className="responsavel-checkbox">
                    <input 
                      type="checkbox" 
                      checked={convidados[index] || false}
                      onChange={(e) => updateResponsavelConvidado(index, e.target.checked)}
                      id={`resp-check-${index}`}
                    />
                    <label htmlFor={`resp-check-${index}`}>Externo</label>
                  </div>
                  <div className="responsavel-actions">
                    <button 
                      type="button" 
                      className="move-responsavel up" 
                      onClick={() => moverResponsavelParaCima(index)}
                      disabled={index === 0}
                      title="Mover para cima"
                    >
                      <FaArrowUp size={14} />
                    </button>
                    <button 
                      type="button" 
                      className="move-responsavel down" 
                      onClick={() => moverResponsavelParaBaixo(index)}
                      disabled={index === responsaveis.length - 1}
                      title="Mover para baixo"
                    >
                      <FaArrowDown size={14} />
                    </button>
                    <button 
                      type="button" 
                      className="remove-responsavel" 
                      onClick={() => removeResponsavel(index)}
                      title="Remover"
                    >
                      <FaTimes size={14} />
                    </button>
                  </div>
                </div>
              ));
            })()}
            
            <button 
              type="button" 
              className="add-responsavel" 
              onClick={addResponsavel}
            >
              <FaPlus size={14} /> <span>Adicionar Responsável</span>
            </button>
          </div>
        </div>
        
        <div className="form-group">
          <label>Tecnologias</label>
          <input 
            type="text" 
            value={Array.isArray(currentItem.technology) ? currentItem.technology.join(',') : currentItem.technology || ''} 
            onChange={(e) => updateArray('technology', e.target.value)}
            placeholder="Tecnologias separadas por vírgula"
          />
        </div>

        <div className="form-group">
          <label>Imagem do Projeto</label>
          <div className="file-upload-area">
            {currentItem.imagemProjeto && (
              <div className="image-preview">
                <img src={currentItem.imagemProjeto} alt="Preview" />
              </div>
            )}
            <input 
              type="file" 
              accept="image/*"
              onChange={(e) => handleFileUpload(e, 'imagem')} 
            />
          </div>
        </div>

        <div className="form-group">
          <label>Documentos do Projeto</label>
          <div className="file-upload-area">
            <input 
              type="file"
              onChange={(e) => handleFileUpload(e, 'documento')} 
            />
          </div>
          
          {currentItem.documentos && currentItem.documentos.length > 0 && (
            <div className="documentos-lista">
              <h4>Documentos Anexados:</h4>
              <ul>
                {currentItem.documentos.map((doc, index) => (
                  <li key={index} className="documento-item">
                    <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.nome}</a>
                    <button 
                      type="button" 
                      className="remove-documento" 
                      onClick={() => removeDocumento(index)}
                    >
                      <FaTimes />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        
        <div className="form-actions">
          <button type="button" className="cancel-button" onClick={cancelEdit}>
            <FaBan /> Cancelar
          </button>
          <button type="button" className="save-button" onClick={saveItem}>
            <FaSave /> Salvar
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="roadmap-admin-container">
      <div className="roadmap-admin-header">
        <button className="back-button" onClick={onBack}>
          <FaArrowLeft /> <span>Voltar</span>
        </button>
        <h2>Administração do Roadmap</h2>
        <div className="admin-actions">
          <button 
            className="refresh-button" 
            onClick={fetchRoadmapItems} 
            disabled={loading}
            title="Atualizar dados"
          >
            <FaSync className={loading ? "spinning" : ""} />
          </button>
          <button 
            className="add-button" 
            onClick={addNewItem} 
            disabled={isEditing || loading}
            title="Adicionar novo projeto"
          >
            <FaPlus /> <span>Novo Projeto</span>
          </button>
        </div>
      </div>

      {/* Mensagem de sucesso ou erro */}
      {message && (
        <div className={`message ${message.type}`}>
          {message.text}
        </div>
      )}

      {/* Estado de carregamento */}
      {loading && (
        <div className="loading-state">
          <FaSpinner className="spinning" />
          <p>Carregando dados...</p>
        </div>
      )}

      {/* Mensagem de erro da API */}
      {error && !loading && roadmapItems.length === 0 && (
        <div className="api-error">
          <FaExclamationTriangle />
          <p>Erro ao carregar dados: {error}</p>
          <button onClick={fetchRoadmapItems}>Tentar novamente</button>
        </div>
      )}

      {/* Formulário de edição */}
      {isEditing && currentItem && (
        renderEditForm()
      )}

      {/* Lista de projetos */}
      {!isEditing && !loading && roadmapItems.length > 0 && (
        <div className="project-list">
          {roadmapItems.map((item, index) => (
            <div key={item.id} className="project-card" style={{ borderLeft: `4px solid ${getStatusColor(item.status)}` }}>
              <div className="project-header">
                <div className="project-icon" style={{ backgroundColor: getStatusColor(item.status) }}>
                  {getIconComponent(item.icon)}
                </div>
                <div className="project-title">
                  <h3>{item.title}</h3>
                  <div className="project-subtitle">
                    <span className="project-period">{item.cardTitle} | {item.cardSubtitle}</span>
                    <span className="project-status" style={{ backgroundColor: getStatusColor(item.status) }}>
                      {item.status}
                    </span>
                  </div>
                </div>
                <div className="project-order-controls">
                  <button 
                    className="move-project up" 
                    onClick={() => moverProjetoParaCima(item)}
                    disabled={index === 0}
                    title="Mover para cima"
                  >
                    <FaArrowUp size={14} />
                  </button>
                  <button 
                    className="move-project down" 
                    onClick={() => moverProjetoParaBaixo(item)}
                    disabled={index === roadmapItems.length - 1}
                    title="Mover para baixo"
                  >
                    <FaArrowDown size={14} />
                  </button>
                </div>
              </div>
              
              <div className="project-technologies">
                {renderTechnologies(item.technology)}
              </div>
              
              <p className="project-description">{item.cardDetailedText}</p>
              
              <div className="project-responsaveis">
                <strong>Responsáveis:</strong> {getResponsaveis(item.responsaveis).join(', ')}
              </div>
              
              <div className="project-actions">
                <button className="edit-button" onClick={() => editItem(item)}>
                  <FaEdit /> Editar
                </button>
                <button className="delete-button" onClick={() => removeItem(item.id)}>
                  <FaTrashAlt /> Remover
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Mensagem quando não há projetos */}
      {!loading && !error && roadmapItems.length === 0 && (
        <div className="no-projects">
          <p>Não há projetos no roadmap ainda.</p>
          <button onClick={addNewItem}>Adicionar Primeiro Projeto</button>
        </div>
      )}
    </div>
  );
};

export default RoadmapAdmin; 