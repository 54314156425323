import { createClient } from '@supabase/supabase-js';

// Credenciais fornecidas pelo usuário
const supabaseUrl = 'https://czfbkyvmullhqcrabccs.supabase.co';
const supabaseKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN6ZmJreXZtdWxsaHFjcmFiY2NzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDI2MTMyOTksImV4cCI6MjA1ODE4OTI5OX0.WRMGcTe6_DhQxM4qOkUnMqFYBB_1ZJdd6T011VHHz5Y';

// Para produção, use as variáveis de ambiente:
// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
// const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseKey);
