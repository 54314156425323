import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Modal from 'react-modal';
import '../styles/Roadmap.css';
import { supabase } from '../lib/supabase';
import { 
  FaCheckCircle, 
  FaSpinner,
  FaExclamationTriangle,
  FaTimes,
  FaPlay,
  FaArrowLeft,
  FaRegCalendarAlt,
  FaUserAlt,
  FaCode,
  FaInfoCircle,
  FaSync,
  FaFile
} from 'react-icons/fa';

// Configurar o elemento raiz para o Modal
Modal.setAppElement('#root');

const Roadmap = ({ onBack }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [animate, setAnimate] = useState(true);
  const [roadmapItems, setRoadmapItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Função para buscar dados do Supabase
  const fetchRoadmapItems = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Buscar dados da tabela roadmap no Supabase
      const { data, error: supabaseError } = await supabase
        .from('roadmap')
        .select('*')
        .order('ordem', { ascending: true }) // Ordenar pelo campo ordem primeiro
        .order('id', { ascending: true }); // Usar ID como critério secundário

      if (supabaseError) {
        throw new Error(supabaseError.message);
      }
      
      if (data && data.length > 0) {
        setRoadmapItems(data);
        // Armazenar no localStorage como backup
        localStorage.setItem('roadmapItems', JSON.stringify(data));
      }
    } catch (error) {
      console.error('Erro ao buscar dados do Supabase:', error);
      setError('Não foi possível carregar os dados. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  // Carregar dados quando o componente é montado
  useEffect(() => {
    fetchRoadmapItems();
    
    // Iniciar a animação
    const timer = setTimeout(() => {
      setAnimate(false);
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Função para lidar com erros de tecnologias (pode ser string JSON ou array)
  const renderTechnologies = (technology) => {
    if (!technology) return [];
    
    let techArray = technology;
    
    // Se for string, tentar parseá-la como JSON
    if (typeof technology === 'string') {
      try {
        techArray = JSON.parse(technology);
      } catch (error) {
        // Se não for JSON válido, dividir por vírgulas
        techArray = technology.split(',').map(item => item.trim());
      }
    }
    
    // Garantir que é um array
    if (!Array.isArray(techArray)) {
      return [];
    }
    
    return techArray;
  };

  // Determinar o ícone com base no status do projeto
  const getIconByStatus = (status) => {
    switch (status) {
      case 'concluído':
        return <FaCheckCircle />;
      case 'em andamento':
        return <FaSpinner className="spinning" />;
      case 'planejado':
        return <FaRegCalendarAlt />;
      case 'atrasado':
        return <FaExclamationTriangle />;
      case 'cancelado':
        return <FaTimes />;
      case 'iniciando':
        return <FaPlay />;
      default:
        return <FaInfoCircle />;
    }
  };

  // Obter a cor com base no status
  const getColorByStatus = (status) => {
    switch (status) {
      case 'concluído':
        return '#4CAF50'; // Verde
      case 'em andamento':
        return '#2196F3'; // Azul
      case 'planejado':
        return '#9C27B0'; // Roxo
      case 'atrasado':
        return '#FF9800'; // Laranja
      case 'cancelado':
        return '#F44336'; // Vermelho
      case 'iniciando':
        return '#00BCD4'; // Ciano
      default:
        return '#607D8B'; // Cinza
    }
  };

  // Abrir o modal com os detalhes do projeto
  const openModal = (project) => {
    setSelectedProject(project);
    setModalIsOpen(true);
  };

  // Fechar o modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Verificar se responsáveis é string ou array e retornar string formatada
  const getResponsaveis = (responsaveis) => {
    if (!responsaveis) return [];
    
    if (typeof responsaveis === 'string') {
      return responsaveis.split(',').map(nome => nome.trim()).filter(Boolean);
    } else if (Array.isArray(responsaveis)) {
      return responsaveis.filter(Boolean);
    }
    
    return [];
  };

  // Função para calcular o ROI
  const calculateROI = (economia, custo) => {
    const economiaNumerica = parseFloat(economia);
    const custoNumerico = parseFloat(custo);
    
    if (!economiaNumerica || !custoNumerico || custoNumerico === 0) {
      return 'N/A';
    }
    
    const roi = ((economiaNumerica - custoNumerico) / custoNumerico) * 100;
    return roi.toFixed(2) + '%';
  };

  // Função para formatar as tecnologias como array
  const formatTechnologies = (technology) => {
    if (!technology) return [];
    if (Array.isArray(technology)) return technology;
    if (typeof technology === 'string') {
      if (technology.includes(',')) {
        return technology.split(',').map(tech => tech.trim());
      }
      return [technology];
    }
    return [];
  };

  // Renderizar responsáveis com suas funções
  const renderResponsaveis = (project) => {
    if (!project.responsaveis) return null;

    const nomes = project.responsaveis.split(',').map(nome => nome.trim()).filter(Boolean);
    const funcoes = project.responsaveis_funcoes ? JSON.parse(project.responsaveis_funcoes) : [];
    const convidados = project.responsaveis_convidados ? JSON.parse(project.responsaveis_convidados) : [];

    return nomes.map((nome, index) => (
      <div 
        key={index} 
        className={`responsavel-item ${index === 0 ? 'responsavel-principal' : 'responsavel-secundario'} ${convidados[index] ? 'convidado' : ''}`}
      >
        <FaUserAlt className={`responsavel-icon ${convidados[index] ? 'convidado' : ''}`} />
        <div className="responsavel-info">
          <span className="responsavel-nome">{nome}</span>
          {funcoes[index] && (
            <span className="responsavel-funcao">{funcoes[index]}</span>
          )}
        </div>
        {convidados[index] && (
          <span className="responsavel-tag">Externo</span>
        )}
      </div>
    ));
  };

  return (
    <div className={`roadmap-container ${animate ? 'fade-in' : ''}`}>
      <div className="roadmap-header">
        <button className="back-button" onClick={onBack}>
          <FaArrowLeft /> Voltar
        </button>
        <h2>Roadmap Tecnológico 2024-2025</h2>
        {error && (
          <button 
            className="refresh-button" 
            onClick={fetchRoadmapItems} 
            title="Tentar novamente"
          >
            <FaSync className={loading ? "spinning" : ""} />
          </button>
        )}
      </div>

      {/* Estado de carregamento */}
      {loading && (
        <div className="loading-state">
          <FaSpinner className="spinning" />
          <p>Carregando dados...</p>
        </div>
      )}

      {/* Mensagem de erro da API quando não há dados em fallback */}
      {error && !loading && roadmapItems.length === 0 && (
        <div className="api-error">
          <FaExclamationTriangle />
          <p>Erro ao carregar dados: {error}</p>
          <button onClick={fetchRoadmapItems}>Tentar novamente</button>
        </div>
      )}

      {!loading && roadmapItems.length > 0 && (
        <div className="roadmap-content">
          <div className="roadmap-intro">
            <h3>Planejamento Estratégico de TI</h3>
            <p>
              Visualize o cronograma de projetos e iniciativas da equipe de Tecnologia da Informação 
              para os próximos meses. Clique em um projeto para ver mais detalhes.
            </p>
          </div>

          <VerticalTimeline animate={animate} lineColor="#ff6b00">
            {roadmapItems.map((item) => (
              <VerticalTimelineElement
                key={item.id}
                className="vertical-timeline-element"
                contentStyle={{ 
                  background: '#fff', 
                  color: '#333',
                  boxShadow: '0 3px 15px rgba(0,0,0,0.1)',
                  borderRadius: '12px',
                  padding: '25px'
                }}
                contentArrowStyle={{ borderRight: '10px solid #fff' }}
                date={item.cardTitle + " · " + item.cardSubtitle}
                iconStyle={{ 
                  background: getColorByStatus(item.status), 
                  color: '#fff',
                  boxShadow: `0 0 0 4px #fff, 0 0 0 5px ${getColorByStatus(item.status)}, 0 0 0 10px rgba(255, 255, 255, 0.5)`
                }}
                icon={getIconByStatus(item.status)}
              >
                <h3 className="vertical-timeline-element-title">{item.title}</h3>
                <p className="vertical-timeline-element-subtitle">
                  <span 
                    className="status-badge" 
                    style={{ backgroundColor: getColorByStatus(item.status) }}
                  >
                    {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                  </span>
                  {item.responsaveis && (
                    <span className="responsaveis">
                      <FaUserAlt style={{ marginRight: '5px' }} />
                      {(() => {
                        const responsaveis = getResponsaveis(item.responsaveis);
                        if (responsaveis.length > 0) {
                          return (
                            <>
                              <span className="responsavel-principal-nome">{responsaveis[0]}</span>
                              {responsaveis.length > 1 && (
                                <span className="responsavel-mais">+{responsaveis.length - 1}</span>
                              )}
                            </>
                          );
                        }
                        return 'Não definido';
                      })()}
                    </span>
                  )}
                </p>
                <p>{item.cardDetailedText}</p>
                <div className="technology-tags">
                  {renderTechnologies(item.technology).map((tech, index) => (
                    tech && <span key={index} className="tech-tag">
                      <FaCode style={{ marginRight: '5px' }} />
                      {tech}
                    </span>
                  ))}
                </div>
                <button className="details-button" onClick={() => openModal(item)}>
                  Ver Detalhes
                </button>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            className="project-modal"
            overlayClassName="modal-overlay"
            contentLabel="Detalhes do Projeto"
          >
            {selectedProject && (
              <div className="modal-content">
                <button className="modal-close" onClick={() => setModalIsOpen(false)}>
                  <FaTimes />
                </button>
                
                <div className="modal-header" style={{ 
                  backgroundImage: selectedProject.imagemProjeto ? `url(${selectedProject.imagemProjeto})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}>
                  <div className="modal-header-overlay"></div>
                  
                  <div className="modal-icon" style={{ 
                    backgroundColor: selectedProject.iconColor || getColorByStatus(selectedProject.status)
                  }}>
                    {getIconByStatus(selectedProject.status)}
                  </div>
                  
                  <div className="modal-title">
                    <h2>{selectedProject.title}</h2>
                    <div className="modal-subtitle">
                      <div className="modal-period">
                        <FaRegCalendarAlt /> {selectedProject.cardTitle} ({selectedProject.cardSubtitle})
                      </div>
                      <div className="status-badge" style={{ backgroundColor: getColorByStatus(selectedProject.status) }}>
                        {selectedProject.status}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="modal-body">
                  <div className="modal-section">
                    <h3>Descrição</h3>
                    <div 
                      className="description-text"
                      dangerouslySetInnerHTML={{ __html: selectedProject.description }}
                    />
                  </div>
                  
                  {(selectedProject.custoTotal || selectedProject.economiaEsperada) && (
                    <div className="modal-section financials">
                      <h3>Informações Financeiras</h3>
                      {selectedProject.custoTotal && (
                        <div className="financial-item">
                          <strong>Custo Total:</strong> R$ {parseFloat(selectedProject.custoTotal).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                        </div>
                      )}
                      {selectedProject.economiaEsperada && (
                        <div className="financial-item">
                          <strong>Economia Esperada:</strong> R$ {parseFloat(selectedProject.economiaEsperada).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                        </div>
                      )}
                      {selectedProject.custoTotal && selectedProject.economiaEsperada && (
                        <div className="financial-item roi">
                          <strong>ROI Esperado:</strong> {calculateROI(selectedProject.economiaEsperada, selectedProject.custoTotal)}
                        </div>
                      )}
                    </div>
                  )}
                  
                  <div className="modal-section">
                    <h3>Responsáveis</h3>
                    <div className="responsaveis-container">
                      {renderResponsaveis(selectedProject)}
                    </div>
                  </div>
                  
                  {selectedProject.technology && (
                    <div className="modal-section">
                      <h3>Tecnologias</h3>
                      <div className="tech-tags">
                        <FaCode style={{ marginRight: '8px' }} />
                        {formatTechnologies(selectedProject.technology).map((tech, index) => (
                          <span key={index} className="tech-tag">{tech}</span>
                        ))}
                      </div>
                    </div>
                  )}
                  
                  {selectedProject.documentos && selectedProject.documentos.length > 0 && (
                    <div className="modal-section">
                      <h3>Documentos</h3>
                      <div className="documentos-list">
                        {selectedProject.documentos.map((doc, index) => (
                          <a key={index} 
                             href={doc.url} 
                             target="_blank" 
                             rel="noopener noreferrer"
                             className="documento-link">
                            <FaFile style={{ marginRight: '8px' }} />
                            {doc.nome}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="modal-footer">
                  <button className="modal-close-btn" onClick={() => setModalIsOpen(false)}>
                    Fechar
                  </button>
                </div>
              </div>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Roadmap; 